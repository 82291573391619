import React, { useEffect, useMemo, useState } from "react"
import { Button, Card, CardBody } from "reactstrap"
import ListCardTableContainer from "./ListCardTableContainer"
import Cookies from "js-cookie"

const ListCard = props => {
  const api_url = process.env.REACT_APP_API_URL
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [columnOrder, setColumnOrder] = useState()

  useEffect(() => {
    loadConfigs()
  }, [])

  const loadConfigs = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      const response = await fetch(api_url + props.card.url, { headers })

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      console.log(jsonData)
      setData(jsonData.data)
      setColumnOrder(jsonData.column_order.split(","))
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <h6>
        {props.section}
        {" > "}
        {props.card.card_title}
      </h6>

      <Card>
        <CardBody>
          {loading && <div>Loading...</div>}
          {!loading && data && (
            <div className="p-2">
              <div>
                <ListCardTableContainer
                  data={data}
                  columnOrder={columnOrder}
                  loading={loading}
                  customPageSize={50}
                  totalRows={data.length}
                  refreshTable={loadConfigs}
                />
              </div>

              <div className="modal-footer mt-2">
                <Button
                  color="secondary"
                  className="btn btn-secondary btn-sm"
                  onClick={props.cancel}
                >
                  cancel
                </Button>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ListCard
