import QdContainer from "components/Common/QdContainer"
import { useFormik } from "formik"
import Success from "pages/NotificationModal/Success"
import React, { useEffect, useState } from "react"
import LoadingOverlay from "react-loading-overlay"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import * as Yup from "yup"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap"
import Cookies from "js-cookie"

const NewLocation = () => {
  const { locationId, locationName } = useParams()

  const [loading, setLoading] = useState(false)
  const [locations, setLocations] = useState([])
  const [selectedLoc, setSelectedLoc] = useState()
  const [tmsLocationId, setTmsLocationId] = useState()
  const [showSuccess, setShowSuccess] = useState(false)
  const [logs, setLogs] = useState()
  const [isFormDisabled, setFormDisabled] = useState(false)
  const [mapType, setMapType] = useState("locationId")
  const api_url = process.env.REACT_APP_API_URL

  const navigate = useNavigate()

  const location = useLocation()
  const { m_loc_id } = location.state || {}

  useEffect(() => {
    var loc = locationId + " - " + locationName
    setSelectedLoc(loc)
    fetchLocations()
  }, [])

  const fetchLocations = async () => {}

  const cancel = () => {
    navigate("/location-management")
  }

  const closeSuccess = () => {
    setShowSuccess(false)
  }

  const save = async values => {
    setLoading(true)
    console.log(values.tms_loc_id)
    try {
      const token = Cookies.get("idToken")
      var url = api_url

      url = url + "email/location/map"

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tms_loc_id: values.tms_loc_id.trim(),
          tms_loc_nm: values.tms_loc_nm.trim(),
          m_loc_id: m_loc_id,
        }),
      }

      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        setErrorMessage(res.message)
        setLoading(false)
        throw new Error(res.message)
      }
      const res = await response.json()
      setLogs(res.logs)
      console.log("hello success" + res.message)
      setLoading(false)
      setFormDisabled(true)
      setShowSuccess(true)
    } catch (error) {
      console.error("Error:" + error.message)
      setLoading(false)
      console.error(error.message)
    }
  }

  // const validate = (value) => {
  //     setTmsLocationId(value)
  // }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      tms_loc_id: "",
      tms_loc_nm: "",
      address: {
        addr_line: "",
        city: "",
        state: "",
        zip: "",
      },
      mapType: mapType,
    },

    // validationSchema: Yup.object({

    // }),

    onSubmit: values => {
      save(values)
    },
  })

  const handleRadioChange = value => {
    setMapType(value)
  }

  return (
    <QdContainer>
      <Modal isOpen={showSuccess} centered size="sm">
        <Success
          message={"Successfully saved the location mapping !!"}
          close={closeSuccess}
        />
      </Modal>
      <LoadingOverlay active={loading} spinner>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Card className="p-4">
            <CardBody>
              <Row className="mb-4">
                <Col md={3}>
                  <Label>Location</Label>
                </Col>
                <Col md={6}>
                  <Input
                    className="form-control"
                    value={selectedLoc}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md={3}>
                  <Label> Location Map Type</Label>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="mapType"
                          id="exampleRadios1"
                          value="locationId"
                          defaultChecked
                          onChange={e => {
                            handleRadioChange(e.target.value)
                          }}
                          disabled={isFormDisabled}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          Location Id
                        </label>
                        {"  "}
                      </div>
                    </Col>
                    <Col>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="mapType"
                          id="exampleRadios2"
                          value="address"
                          onChange={e => {
                            handleRadioChange(e.target.value)
                          }}
                          disabled={isFormDisabled}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          Address
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col md={3}>
                  <Label>Location Name</Label>
                </Col>
                <Col md={6}>
                  {/* <Input className='form-control' name="tms_loc_nm" placeholder='Enter the location Id' onChange={e => validate(e.target.value)} /> */}
                  <Input
                    type="text"
                    className="form-control"
                    name="tms_loc_nm"
                    id="horizontal-tms_loc_nm-Input"
                    placeholder="Enter the location name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.tms_loc_nm || ""}
                    disabled={isFormDisabled}
                    invalid={
                      validation.touched.tms_loc_nm &&
                      validation.errors.tms_loc_nm
                        ? true
                        : false
                    }
                  />
                </Col>
              </Row>
              {mapType == "locationId" && (
                <Row className="mb-4">
                  <Col md={3}>
                    <Label>Location Id</Label>
                  </Col>
                  <Col md={6}>
                    {/* <Input className='form-control' name="tms_loc_id"  onChange={e => validate(e.target.value)} /> */}
                    <Input
                      type="text"
                      className="form-control"
                      name="tms_loc_id"
                      id="horizontal-tms_loc_id-Input"
                      placeholder="Enter the new TMS location id"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.tms_loc_id || ""}
                      invalid={
                        validation.touched.tms_loc_id &&
                        validation.errors.tms_loc_id
                          ? true
                          : false
                      }
                      disabled={isFormDisabled}
                    />
                  </Col>
                </Row>
              )}
              {mapType == "address" && (
                <div>
                  <Row className="mb-4">
                    <Col md={3}>
                      <Label>Address</Label>
                    </Col>
                    <Col md={6}>
                      {/* <Input className='form-control' name="address.addr_line" placeholder='Enter the address line' onChange={e => validate(e.target.value)} /> */}
                      <Input
                        type="text"
                        className="form-control"
                        name={"address.addr_line"}
                        id="horizontal-address.addr_line-Input"
                        placeholder="Enter the address line"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values?.address?.addr_line || ""}
                        invalid={
                          validation.touched.address?.addr_line &&
                          validation.errors.address?.addr_line
                            ? true
                            : false
                        }
                        disabled={isFormDisabled}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col md={3}></Col>
                    <Col md={6}>
                      {/* <Input className='form-control' name="address.city" placeholder='Enter the city' onChange={e => validate(e.target.value)} /> */}
                      <Input
                        type="text"
                        className="form-control"
                        name="address.city"
                        id="horizontal-address.city-Input"
                        placeholder="Enter the City"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address?.city || ""}
                        invalid={
                          validation.touched.address?.city &&
                          validation.errors.address?.city
                            ? true
                            : false
                        }
                        disabled={isFormDisabled}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col md={3}></Col>
                    <Col md={6}>
                      {/* <Input className='form-control' name="address.city" placeholder='Enter the city' onChange={e => validate(e.target.value)} /> */}
                      <Input
                        type="text"
                        className="form-control"
                        name="address.state"
                        id="horizontal-state-Input"
                        placeholder="Enter the state"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address?.state || ""}
                        maxLength={2}
                        invalid={
                          validation.touched.address?.state &&
                          validation.errors.address?.state
                            ? true
                            : false
                        }
                        disabled={isFormDisabled}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col md={3}></Col>
                    <Col md={6}>
                      {/* <Input className='form-control' name="address.zip" placeholder='Enter the city' onChange={e => validate(e.target.value)} /> */}
                      <Input
                        type="text"
                        className="form-control"
                        name="address.zip"
                        id="horizontal-state-Input"
                        placeholder="Enter the zip"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address?.zip || ""}
                        maxLength={2}
                        invalid={
                          validation.touched.address?.zip &&
                          validation.errors.address?.zip
                            ? true
                            : false
                        }
                        disabled={isFormDisabled}
                      />
                    </Col>
                  </Row>
                </div>
              )}

              <Row>
                <Col md={3}></Col>
                <Col md={6}>
                  <Button
                    type="submit"
                    className="btn btn-primary m-2"
                    color="primary"
                    disabled={isFormDisabled}
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-secondary m-2"
                    color="secondary"
                    onClick={cancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
        {logs && (
          <Card className="p-4">
            <h5 className="text-primary">Logs</h5>
            <CardBody>
              <ul>
                {logs.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </CardBody>
          </Card>
        )}
      </LoadingOverlay>
    </QdContainer>
  )
}

export default NewLocation
