import React from "react"
import { Button, Col, Row } from "reactstrap"
import moment from "moment"

import classes from "./OpenDockDetails.scss"

const OpenDockDetails = props => {
  let stop = props.stop
  let data = props.data

  const parseDate = datestr => {
    console.log(data)
    let date = moment(datestr).format("dddd, MMMM Do YYYY")
    return date
  }

  const copyClipBoard = content => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        alert("Content copied into clipboard !!!")
      })
      .catch(err => {
        alert(err)
      })
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Confirmation Details</h5>
        <button
          type="button"
          onClick={() => {
            props.closeOpenDockModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col md={7}>
            <Row>
              <h4 className="text-primary">
                <span>
                  <i className="bx bx-calendar-check ml-2"></i>{" "}
                  {parseDate(stop.scheduledAppointmentDate)}{" "}
                  {stop.scheduledAppointmentTime}
                </span>
              </h4>
            </Row>
            <Row className="p-2">
              <Col md={4}>
                <span className="font-weight-bold">Confirmation#</span>
              </Col>
              <Col md={8}>
                <span className="card-title-desc">{data?.conf_code}</span>{" "}
                <Button
                  color="primary"
                  className="btn-sm"
                  onClick={() => {
                    copyClipBoard(data?.conf_code)
                  }}
                >
                  Copy
                </Button>
              </Col>
            </Row>
            <Row className="p-2">
              <Col md={4}>
                <span className="font-weight-bold">Appointment Id</span>
              </Col>
              <Col md={8}>
                <span className="text-muted">{data?.appointment_id}</span>{" "}
                {data?.appointment_id && (
                  <Button
                    color="primary"
                    className="btn-sm"
                    onClick={() => {
                      copyClipBoard(data?.appointment_id)
                    }}
                  >
                    Copy
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="p-2">
              <Col md={4}>
                <span className="font-weight-bold">Dock Name</span>
              </Col>
              <Col md={8}>
                <span className="card-title-desc">{data?.dock_name}</span>
              </Col>
            </Row>
            <Row className="p-2">
              <Col md={4}>
                <span className="font-weight-bold">Load Type</span>
              </Col>
              <Col md={8}>
                <span className="card-title-desc">{data?.load_type}</span>{" "}
              </Col>
            </Row>
            <Row className="p-2">
              <Col md={4}>
                <span className="font-weight-bold">PO Number</span>
              </Col>
              <Col md={8}>
                <span className="card-title-desc">
                  {data?.custom?.poNumber}
                </span>{" "}
              </Col>
            </Row>
            <Row className="p-2">
              <Col md={4}>
                <span className="font-weight-bold">Pallet Count</span>
              </Col>
              <Col md={8}>
                <span className="card-title-desc">
                  {data?.custom?.palletCount}
                </span>{" "}
              </Col>
            </Row>
          </Col>
          <Col md={1} className="">
            <div className="vr vr-new"></div>
          </Col>
          <Col md={4}>
            <Row>
              <h4 className="text-primary d-flex">
                <span>
                  <i className="bx bx-building-house"></i> Warehouse
                </span>
              </h4>
            </Row>
            <Row>
              <span>{stop.tmsLocationName}</span>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={props.closeOpenDockModal}
        >
          OK
        </button>
      </div>
    </React.Fragment>
  )
}

export default OpenDockDetails
