import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import Cookies from "js-cookie"

const OIDCDetails = props => {
  const [loading, setLoading] = useState(false)
  const api_url = process.env.REACT_APP_API_URL
  const [data, setData] = useState(null)
  const [isNew, setIsNew] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getData()
  }, [])

  const openEditForm = () => {
    navigate("/auth-config/new")
  }

  const getData = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(api_url + "idp/code-grant", { headers })

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      // if (jsonData) {
      //     setIsNew(false)
      // }
      setData(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  return (
    <>
      {loading && <div> Loading...</div>}
      {!loading && data && (
        <div>
          {showProviderDetails(data)}
          {showClientDetails(data)}
        </div>
      )}
      {!loading && !data && (
        <Card>
          <CardBody className="p-3">
            <div className="text-center mt-3">
              <span className="mt-3">No configuration found</span>
              <div className="mt-4">
                <Button
                  type="button"
                  color="primary"
                  className="w-md"
                  onClick={openEditForm}
                >
                  Create New
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  )
}

export default OIDCDetails

function showClientDetails(data) {
  return (
    <Card outline color="primary" className="border mt-3 mb-3">
      <CardBody className="p-2 cardBody">
        <Row className="mb-2 font-weight-100">
          <Col md="4" className={"text-muted"}>
            <span>App Client Name:</span>
          </Col>
          <Col md="8">
            <span>{data.client_response.app_client_name}</span>
          </Col>
        </Row>
        <Row className="mb-2 font-weight-100">
          <Col md="4" className={"text-muted"}>
            <span>App Client Id:</span>
          </Col>
          <Col md="8">
            <span>{data.client_response.app_client_id}</span>
          </Col>
        </Row>
        <Row className="mb-2 font-weight-100">
          <Col md="4" className={"text-muted"}>
            <span>Callback Url</span>
          </Col>
          <Col md="8">
            <span>{data.client_response.call_back_urls}</span>
          </Col>
        </Row>
        <Row className="mb-2 font-weight-100">
          <Col md="4" className={"text-muted"}>
            <span>Sign-in Redirect Url</span>
          </Col>
          <Col md="8">
            <a href={data.sign_in_redirect_uri}>{data.sign_in_redirect_uri}</a>
          </Col>
        </Row>
        <Row className="mb-2 font-weight-100">
          <Col md="4" className={"text-muted"}>
            <span>Tenant Login URL</span>
          </Col>
          <Col md="8">
            <a href={data.tenant_login_url}>{data.tenant_login_url}</a>
          </Col>
        </Row>
        <Row className="mb-2 font-weight-100">
          <Col md="4" className={"text-muted"}>
            <span>Logout URL</span>
          </Col>
          <Col md="8">
            <span>{data.logout_url}</span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

function showProviderDetails(data) {
  return (
    <Card outline color="primary" className="border mt-3 mb-3">
      <CardBody className="p-2 cardBody">
        <Row className="mb-2 font-weight-100">
          <Col md="4" className={"text-muted"}>
            <span>Provider Name:</span>
          </Col>
          <Col md="8">
            <span>{data.idp_response.provider_name}</span>
          </Col>
        </Row>
        <Row className="mb-2 font-weight-100">
          <Col md="4" className={"text-muted"}>
            <span>Provider Type:</span>
          </Col>
          <Col md="8">
            <span>{data.idp_response.provider_type}</span>
          </Col>
        </Row>
        <Row className="mb-2 font-weight-100">
          <Col md="4" className={"text-muted"}>
            <span>Issuer:</span>
          </Col>
          <Col md="8">
            <a href={data.idp_response.oidc_issuer}>
              {data.idp_response.oidc_issuer}
            </a>
          </Col>
        </Row>
        <Row className="mb-2 font-weight-100">
          <Col md="4" className={"text-muted"}>
            <span>Client Id:</span>
          </Col>
          <Col md="8">
            <span>{data.idp_response.client_id}</span>
          </Col>
        </Row>
        <Row className="mb-2 font-weight-100">
          <Col md="4" className={"text-muted"}>
            <span>Client Secret:</span>
          </Col>
          <Col md="8">
            <span>{data.idp_response.client_secret}</span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
