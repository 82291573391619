import React, { useEffect, useRef, useState } from "react"
import { Tbody, Thead } from "react-super-responsive-table"
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap"
import ValidationForm from "./ValidationForm"
import * as Yup from "yup"
import { useFormik } from "formik"

const TemplateValidations = props => {
  const [validations, setValidations] = useState(props.validations || [])
  const [showAddForm, setShowAddForm] = useState(false)
  const [editingVal, setEditingVal] = useState(null)
  const [editingValIndex, setEditingValIndex] = useState(null)
  const formikRef = useRef()
  const [vars, setVars] = useState(props.vars || [])

  const addVal = () => {
    setEditingVal(null)
    setEditingValIndex(null)
    setShowAddForm(true)
  }

  const togAddForm = () => {
    setShowAddForm(!showAddForm)
  }

  const addNewVal = val => {
    if (editingVal) {
      var vals = [...validations]
      vals[editingValIndex] = val
      setValidations(vals)
      props.setValidations(vals)
    } else {
      if (validations?.length > 0) {
        var newVals = [...validations]
        newVals.push(val)
        setValidations(newVals)
        props.setValidations(newVals)
      } else {
        var vals = []
        vals.push(val)
        setValidations(vals)
        props.setValidations(vals)
      }
    }

    setEditingVal(null)
    setEditingValIndex(null)
    togAddForm()
  }

  useEffect(() => {
    console.log(validations)
    setEditingVal(editingVal)
  }, [editingVal])

  const editVal = (val, index) => {
    setEditingVal(val)
    setEditingValIndex(index)
    setShowAddForm(true)
  }

  const removeVal = index => {
    var vals = validations?.filter((item, idx) => idx !== index)
    setValidations(vals)
    props.setValidations(vals)
  }

  return (
    <>
      <Modal isOpen={showAddForm} toggle={togAddForm}>
        <ValidationForm
          closeModal={togAddForm}
          onSave={addNewVal}
          valData={editingVal}
          vars={vars}
        />
      </Modal>
      <Row>
        <Label htmlFor="desc" className="col-sm-2 col-form-label">
          Validations
        </Label>
        <Col md={10}>
          {(!validations || validations.length == 0) && !props.isEdit && (
            <div className="text-muted">{"No validation added."}</div>
          )}
          {validations?.length > 0 && (
            <Table>
              <Thead>
                <tr>
                  <th>Validation Type</th>
                  <th>Name</th>
                  <th>Var</th>
                  <th>Value</th>

                  <th>Message</th>
                  {props.isEdit && <th>Action</th>}
                </tr>
              </Thead>
              <Tbody>
                {validations.map((val, index) => (
                  <tr key={index}>
                    <td>{val.valdn_type}</td>
                    <td>{val.name}</td>
                    <td>{val.var}</td>
                    <td>{val.value}</td>
                    <td>{val.message}</td>
                    {props.isEdit && (
                      <td>
                        <i
                          className="mdi mdi-pencil font-size-16 text-success me-1 pointer"
                          onClick={() => editVal(val, index)}
                        />{" "}
                        <i
                          className="mdi mdi-trash-can font-size-16 text-danger me-1"
                          onClick={() => removeVal(index)}
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </Tbody>
            </Table>
          )}

          {props.isEdit && (
            <Button
              type="button"
              className="btn, btn-sm"
              color="primary"
              onClick={addVal}
            >
              Add
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}

export default TemplateValidations
