import React, { useState } from "react"
import { Button, Col, Label, Row } from "reactstrap"

import "./ReportDetails.scss"

function ReportDetails({
  data,
  onPrev,
  onNext,
  isPrevDisabled,
  isNextDisabled,
  onClose,
}) {
  const [transitionDirection, setTransitionDirection] = useState("")
  const formatEmailContent = content => {
    // Replace `\n` with HTML line breaks
    let formattedContent = content.replace(/\n/g, "<br/>")

    // Replace `*text*` with bold tags
    formattedContent = formattedContent.replace(
      /\*(.*?)\*/g,
      "<strong>$1</strong>"
    )

    // Replace email addresses with mailto links
    formattedContent = formattedContent.replace(
      /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g,
      '<a href="mailto:$1">$1</a>'
    )

    return formattedContent
  }

  const handleNext = () => {
    setTransitionDirection("right")
    setTimeout(() => {
      onNext()
      setTransitionDirection(null)
    }, 500)
  }

  const handlePrev = () => {
    setTransitionDirection("left")
    setTimeout(() => {
      onPrev()
      setTransitionDirection(null)
    }, 500)
  }

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Report Details</h5>

        <button
          type="button"
          onClick={() => {
            onClose()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" onClick={() => onClose()}>
            &times;
          </span>
        </button>
      </div>

      <div className="modal-body">
        <Row className="mb-2">
          <div>
            <div className="float-end">
              <div className="d-flex gap-2">
                <Button
                  className="btn-sm outline"
                  disabled={isPrevDisabled}
                  onClick={handlePrev}
                >
                  Previous
                </Button>
                <Button
                  className="btn-sm outline"
                  disabled={isNextDisabled}
                  onClick={handleNext}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Row>
        <div className={`w-100 popup-content ${transitionDirection}`}>
          <Row>
            <Col>
              <Row>
                <Col>
                  <label className="col-3">Tenant</label>
                  <span className="text-primary col-9">{data.tenant_id}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-3">Stop Id</label>
                  <span className="text-primary col-9">{data.st_id}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-3">Location Id</label>
                  <span className="text-primary col-9">{data.m_loc_id}</span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <label className="col-3">Requested On</label>
                  <span className="text-primary">{data.request_sent_on}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-3">Responsed On</label>
                  <span className="text-primary">{data.response_rcvd_on}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="col-3">Confirmation code</label>
                  <span className="text-primary col-9">
                    {data.confirmation_code}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="border mt-1 mb-1" />
          <Row>
            <Col md={2}>
              <Label>Summary</Label>
            </Col>
            <Col md={10}>
              <textarea
                value={data.summary}
                style={{ height: "300px" }}
                className="form-control"
                readOnly
              />
            </Col>
          </Row>

          <div className="border mt-1 mb-1" />
          <Row className="mt-2">
            <Col>
              <div className="text-primary mb-2">Request Email</div>
              <div
                className="border p-2 border-primary"
                dangerouslySetInnerHTML={{
                  __html: data.request_mail,
                }}
                style={{
                  whiteSpace: "pre-wrap",
                  lineHeight: "1.5",
                }}
              />
            </Col>
            <Col>
              <div className="text-primary mb-2">Response Email</div>
              <div
                className="border p-2 border-primary"
                dangerouslySetInnerHTML={{
                  __html: formatEmailContent(data.response_mail),
                }}
                style={{
                  whiteSpace: "pre-wrap",
                  lineHeight: "1.5",
                }}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secodnary"
          onClick={() => onClose()}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default ReportDetails
