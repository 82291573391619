import { useFormik } from "formik"
import React, { useState } from "react"
import LoadingOverlay from "react-loading-overlay"
import { Button, Form, FormFeedback, Input, Label, Modal } from "reactstrap"
import * as Yup from "yup"
import Cookies from "js-cookie"

const EditForm = props => {
  const api_url = process.env.REACT_APP_API_URL
  const [data, setData] = useState(props.configData)
  const [loading, setLoading] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: data?.id,
      name: data?.display_name,
      value: data?.value,
      type: data?.value_type,
    },
    validationSchema: Yup.object({
      value: Yup.string().required("Please enter value"),
    }),
    onSubmit: values => {
      console.log(values.name)
      var val = {
        id: values.id,
        value:
          values.type == "bool"
            ? values.value == "true"
              ? true
              : false
            : values.value,
      }
      save(val)
    },
  })

  const save = async val => {
    try {
      setLoading(true)

      const token = Cookies.get("idToken")

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(val),
      }

      var url = api_url + props.url

      const response = await fetch(url, config)

      if (!response.ok) {
        throw new Error("Request failed")
      }
      try {
        const jsonData = await response.json()
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
      setLoading(false)
      console.log("Saved")
      props.onSave()
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <LoadingOverlay active={loading} spinner>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Update Configuration</h5>
        </div>
        <Form
          className="form-horizontal"
          id="addConfigForm"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <Label className="form-label">Name</Label>
              <Input
                id="varname"
                name="name"
                className="form-control"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || ""}
                disabled
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Value Type</Label>
              <Input
                id="varname"
                name="type"
                className="form-control"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.type || ""}
                disabled
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Value</Label>
              {data?.value_type == "string" && (
                <>
                  <Input
                    id="strvalue"
                    name="value"
                    className="form-control"
                    placeholder="Enter value"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.value || ""}
                    invalid={
                      validation.touched.value && validation.errors.value
                        ? true
                        : false
                    }
                  />
                  {validation.touched.value && validation.errors.value ? (
                    <FormFeedback type="invalid">
                      {validation.errors.value}
                    </FormFeedback>
                  ) : null}
                </>
              )}
              {data?.value_type == "int" && (
                <>
                  <Input
                    id="intvalue"
                    name="value"
                    className="form-control"
                    placeholder="Enter variable name"
                    type="number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.value || ""}
                    invalid={
                      validation.touched.value && validation.errors.value
                        ? true
                        : false
                    }
                  />
                  {validation.touched.value && validation.errors.value ? (
                    <FormFeedback type="invalid">
                      {validation.errors.value}
                    </FormFeedback>
                  ) : null}
                </>
              )}
              {data?.value_type == "bool" && (
                <select
                  className="form-control"
                  name="value"
                  onChange={validation.handleChange}
                  value={validation.values.value}
                >
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              )}
              {data?.value_type == "json" && (
                <>
                  <Input
                    id="jsonvalue"
                    name="value"
                    className="form-control"
                    placeholder="Enter value"
                    type="textarea"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.value || ""}
                    invalid={
                      validation.touched.value && validation.errors.value
                        ? true
                        : false
                    }
                  />
                  {validation.touched.value && validation.errors.value ? (
                    <FormFeedback type="invalid">
                      {validation.errors.value}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                color="primary"
                className="btn btn-primary btn-sm"
              >
                Save
              </Button>
              <Button
                type="button"
                color="secondary"
                className="btn btn-primary btn-sm"
                onClick={props.cancelEdit}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      </LoadingOverlay>
    </React.Fragment>
  )
}

export default EditForm
