import React, { createContext } from "react"
import ResetPassword from "./ResetPassword"
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"

const AccountContext = createContext()

const Account = props => {
  const navigate = useNavigate()

  const getSession = async () => {
    // return await new Promise((resolve, reject) => {
    //   const user = Pool.getCurrentUser()
    //   if (user) {
    //     user.getSession((error, session) => {
    //       if (error) {
    //         reject()
    //       } else {
    //         resolve(session)
    //       }
    //     })
    //   } else {
    //     reject()
    //   }
    // })
  }

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
      })

      const authenticationDetails = new AuthenticationDetails({
        Username,
        Password,
      })

      user.authenticateUser(authenticationDetails, {
        onSuccess: data => {
          console.log("from login " + data)
          resolve(data)
        },
        onFailure: err => {
          console.error("On Failure", err)
          reject(err)
        },

        newPasswordRequired: userAttr => {
          navigate("/resetpassword")
          // user.completeNewPasswordChallenge(
          //   "Admin@1234",
          //   {},
          //   {
          //     onSuccess: data => {
          //       console.log(data)
          //       navigate("/dashboard")
          //     },
          //     onFailure: error => {
          //       console.error(error)
          //     },
          //   }
          // )
        },
      })
    })
  }

  const logout = async () => {
    // const user = Pool.getCurrentUser()
    // if (user) {
    //   user.signOut()
    //   localStorage.removeItem("user")
    //   localStorage.removeItem("idToken")
    //   localStorage.removeItem("profilePic")
    //   localStorage.removeItem("email")
    //   navigate("/")
    // }
  }

  return (
    <AccountContext.Provider value={{ authenticate, getSession, logout }}>
      {props.children}
    </AccountContext.Provider>
  )
}
export { Account, AccountContext }
