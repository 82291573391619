import Cookies from "js-cookie";
import React from "react"
import { Navigate } from "react-router-dom"





const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const Authmiddleware = props => {
  const idToken = Cookies.get('idToken')
  if (idToken) {
    const decodedJwt = parseJwt(idToken);
    console.log("decoded jwt = " + decodedJwt.exp)
    if (decodedJwt.exp * 1000 < Date.now()) {
      localStorage.setItem('redirectUrl', (window.location.pathname + window.location.search).substring(1));
      return (
        <Navigate to={{ pathname: "/"}} />
      )
    }
  } else {
    localStorage.setItem('redirectUrl', (window.location.pathname + window.location.search).substring(1));
    return (
      <Navigate to={{ pathname: "/"}} />
    )
  }
 

  return (
    <React.Fragment>
     {props.children}
    </React.Fragment>
  )

  
}

export default Authmiddleware
