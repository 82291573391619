import QdContainer from "components/Common/QdContainer"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap"
import ViewAddresses from "./ViewAddresses"
import EditAddresses from "./EditAddresses"
import { useParams } from "react-router-dom"
import Cookies from "js-cookie"

const EmailAddressConfig = () => {
  const [data, setData] = useState()
  const { locationId, locationName } = useParams()
  const [loading, setLoading] = useState(false)
  const [view, setView] = useState(true)
  const [isNew, setIsNew] = useState(false)

  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    loadData()
  }, [])

  const reload = () => {
    setView(true)
    loadData()
  }

  const loadData = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      const response = await fetch(
        api_url + "location-management/email-config/" + locationId,
        { headers }
      )

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      setData(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const openEdit = () => {
    setView(false)
  }

  const cancel = () => {
    setView(true)
  }

  return (
    <QdContainer>
      <Card>
        <CardBody>
          <Col>
            <div className="float-start">
              <h5 className="text-primary">Email Addresses</h5>
            </div>
          </Col>
          <Col>
            <div className="float-end">
              <div className="d-flex gap-2">
                <div className="text-sm">
                  <span
                    onClick={() => {
                      loadData()
                    }}
                    className="pointer text-primary"
                  >
                    <i className="fas fa-sync-alt" />
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CardBody>
            {loading && <div className="p-4">Loading..</div>}
            {!loading && !data && view && (
              <div className="text-center mt-3">
                <span className="mt-3">No email-address configured</span>
                <div className="mt-4">
                  <Button
                    type="button"
                    color="primary"
                    className="w-md"
                    onClick={() => {
                      openEdit(true)
                    }}
                  >
                    Create New
                  </Button>
                </div>
              </div>
            )}
            {!loading && data && (
              <div>
                {view && (
                  <ViewAddresses data={data} edit={openEdit} reload={reload} />
                )}
              </div>
            )}
            {!view && (
              <EditAddresses
                data={data}
                cancel={cancel}
                locationId={locationId}
                reload={reload}
              />
            )}
          </CardBody>
        </CardBody>
      </Card>
    </QdContainer>
  )
}

export default EmailAddressConfig
