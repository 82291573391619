import React, { useEffect, useState } from "react"
import { Tbody, Thead } from "react-super-responsive-table"
import { Button, Col, Label, Modal, Row, Table } from "reactstrap"
import GroupCredForm from "./GroupCredForm"

function GroupCred({ creds, isEdit, setCreds }) {
  const [editingCred, setEditingCred] = useState(null)
  const [editingCredIndex, setEditingCredIndex] = useState(null)
  const [showAddForm, setShowAddForm] = useState(false)
  const [credentials, setCredentials] = useState(creds)

  const [colWidth, setColWidth] = useState(isEdit ? 4 : 3)

  console.log("credentials in group creds", credentials)

  useEffect(() => {
    setCredentials(creds)
  }, [])

  //console.log("creds are", creds)

  const editCred = (val, index) => {
    setEditingCred(val)
    setEditingCredIndex(index)
    setShowAddForm(true)
  }

  const removeCred = index => {
    let newCreds = creds?.filter((item, idx) => idx !== index)
    setCredentials(newCreds)
    setCreds(newCreds)
  }

  const addCred = () => {
    setEditingCred(null)
    setEditingCredIndex(null)
    setShowAddForm(true)
  }

  const maskPassword = password => {
    if (password) {
      if (password?.length <= 4) {
        return password // No need to mask short passwords
      } else {
        const firstChar = password.slice(0, 1) // First character
        const lastTwoChars = password.slice(-2) // Last two characters
        const maskedMiddle = "*".repeat(password.length - 3) // Mask middle characters
        return `${firstChar}${maskedMiddle}${lastTwoChars}`
      }
    }
    return null
  }

  const addNewCred = val => {
    console.log("value from add new cred - ", val)

    if (editingCred) {
      var credentials = [...creds]
      credentials[editingCredIndex] = val
      setCreds(credentials)
    } else {
      if (creds) {
        var credentails = [...creds]
        credentails.push(val)
        setCreds(credentails)
      } else {
        var credentials = []
        credentials.push(val)
        setCreds(credentials)
      }
    }

    setEditingCred(null)
    setEditingCredIndex(null)
    togAddForm()
  }

  const togAddForm = () => {
    setShowAddForm(!showAddForm)
  }

  return (
    <>
      <Modal isOpen={showAddForm} toggle={togAddForm}>
        <GroupCredForm
          closeModal={togAddForm}
          onSave={addNewCred}
          credData={editingCred}
          creds={creds}
          isNew={editingCred ? false : true}
          isEdit={isEdit}
        />
      </Modal>
      <Row>
        <Col md={colWidth}>
          <span className="text-muted">Credentials:</span>
        </Col>
        <Col md={8}>
          {creds?.length > 0 && (
            <Table>
              <Thead>
                <tr>
                  <th style={{ border: "none" }}>Group Name</th>
                  <th style={{ border: "none" }}>User Id</th>
                  <th style={{ border: "none" }}>Password</th>

                  {isEdit && <th style={{ border: "none" }}>Action</th>}
                </tr>
              </Thead>
              <Tbody>
                {creds.map((cred, index) => (
                  <tr key={index}>
                    <td style={{ border: "none" }}>{cred.group_name}</td>
                    <td style={{ border: "none" }}>{cred.userId}</td>
                    <td style={{ border: "none" }}>
                      {maskPassword(cred.password)}
                    </td>

                    {isEdit && (
                      <td style={{ border: "none" }}>
                        <i
                          className="mdi mdi-pencil font-size-16 text-success me-1 pointer"
                          onClick={() => editCred(cred, index)}
                        />{" "}
                        <i
                          className="mdi mdi-trash-can font-size-16 text-danger me-1"
                          onClick={() => removeCred(index)}
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </Tbody>
            </Table>
          )}
          {isEdit && (
            <Button
              type="button"
              className="btn, btn-sm"
              color="primary"
              onClick={addCred}
            >
              Add
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}

export default GroupCred
