import React, { useState } from "react"
import LoadingOverlay from "react-loading-overlay"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Modal,
  Row,
} from "reactstrap"
import StopInfoDetails from "./StopInfoDetails"
import StopRelatedOrders from "./StopRelatedOrders"
import Cookies from "js-cookie"

const StopDetail = props => {
  const [showConfirm, setShowConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showRelatedOrderButton, setShowRelatedORderButton] = useState(true)

  const navigate = useNavigate()

  const api_url = process.env.REACT_APP_API_URL

  const confirm = () => {
    setShowConfirm(!showConfirm)
  }

  const toggleShowRelatedOrder = () => {
    setShowRelatedORderButton(!showRelatedOrderButton)
  }

  const confirmAppt = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(
        api_url + "appointment/accept/" + props.data.stopId,
        { headers }
      ) // Replace with your API endpoint

      if (!response.ok) {
        throw new Error("Request failed")
      }

      setLoading(false)
      setShowConfirm(false)

      props.successResult()
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
      setShowConfirm(false)
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showConfirm}
        toggle={() => {
          confirm(null)
        }}
        centered
        size="lg"
      >
        <LoadingOverlay active={loading} spinner>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Confirmation</h5>
            <button
              type="button"
              onClick={() => {
                setShowConfirm(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setShowConfirm(false)}>
                &times;
              </span>
            </button>
          </div>

          <div className="modal-body">
            Are you sure you want to accept the appiontment scheduled on{" "}
            <span className="text-primary">
              {props.data?.scheduledAppointmentDate
                ? props.data.scheduledAppointmentDate
                : ""}{" "}
              @{" "}
              {props.data?.scheduledAppointmentTime
                ? props.data.scheduledAppointmentTime
                : ""}
            </span>
            ?
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                confirmAppt()
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-secodnary"
              onClick={() => setShowConfirm(false)}
            >
              No
            </button>
          </div>
        </LoadingOverlay>
      </Modal>

      {props.data && (
        <Card>
          <CardBody>
            <h3 className="text-primary d-flex justify-content-center">
              Accept appointment
            </h3>
            <Row>
              <Col>
                <h5>Stop Details</h5>
                <Card outline color="primary" className="border">
                  <CardBody>
                    <StopInfoDetails data={props.data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {showRelatedOrderButton && (
              <Row>
                <div className="d-flex justify-content-end">
                  <Button
                    color="link"
                    className="btn btn-link waves-effect"
                    onClick={toggleShowRelatedOrder}
                  >
                    Show related order
                  </Button>
                </div>
              </Row>
            )}
            {!showRelatedOrderButton && (
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <h5>Related orders</h5>
                    </Col>
                    <Col>
                      <div className="d-flex justify-content-end">
                        <Button
                          color="link"
                          className="btn btn-link waves-effect"
                          onClick={toggleShowRelatedOrder}
                        >
                          Hide related orders
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <StopRelatedOrders data={props.data} />
                  </Row>
                </Col>
              </Row>
            )}
          </CardBody>

          <CardFooter className="d-flex justify-content-end">
            {props.data.exceptionReason &&
              props.data.exceptionReason.includes(
                "Appointment confirmed, but"
              ) && (
                <Button
                  color="primary"
                  className="btn btn-primary"
                  onClick={() => confirm()}
                >
                  Accept appointment
                </Button>
              )}
            &nbsp;&nbsp;
            <Button
              color="secondary"
              className="btn btn-secondary"
              onClick={() => navigate("/dashboard")}
            >
              Cancel
            </Button>
          </CardFooter>
        </Card>
      )}
      {!props.data && <div>Loading...</div>}
    </React.Fragment>
  )
}

export default StopDetail
