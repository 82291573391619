import React, { useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import LocationConfigTableContainer from "./LocationConfigTableContainer"
import { Box } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import Cookies from "js-cookie"

const LocationConfiguration = () => {
  const [loading, setLoading] = useState(false)
  const [locationData, setLocationData] = useState([])
  const api_url = process.env.REACT_APP_API_URL

  const navigate = useNavigate()

  const gotoWarehouseConfig = row => {
    const url = `/opendock-warehouse/${row.original.locationId}/${row.original.locationName}`
    navigate(url)
  }

  const gotoEmailTemplate = row => {
    const url = `/loc-config-open-dock/ + ${row.original.locationId}/${row.original.locationName}`
    navigate(url)
  }

  const getColumns = () => {
    return useMemo(
      () => [
        {
          accessor: "locationName", //access nested data with dot notation
          Header: "Name",
          width: 200,
          Cell: ({ row }) => (
            <Box>
              {row.original.locationId && (
                <Link
                  to={`/loc-config-open-dock/${row.original.locationId}/${row.original.locationName}`}
                >
                  {row.original.locationName}
                </Link>
              )}
            </Box>
          ),
        },
        {
          accessor: "address1", //access nested data with dot notation
          Header: "Address",
          width: 100,
        },
        {
          accessor: "city",
          Header: "City",
          width: 40,
        },
        {
          accessor: "state",
          Header: "State",
          width: 20,
        },
        {
          accessor: "locationTimeZone", //normal accessor
          Header: "TimeZone",
          width: 80,
        },
        {
          accessor: "",
          Header: "Action",
          width: 20,
          Cell: ({ row }) => (
            <div className="d-flex justify-content-center">
              <UncontrolledDropdown>
                <DropdownToggle href="#" className="card-drop" tag="a">
                  <i className="mdi mdi-dots-horizontal font-size-18" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem href="#" onClick={() => gotoEmailTemplate(row)}>
                    <i className="mdi mdi-card-bulleted-settings-outline text-success me-1" />{" "}
                    Template Configuration
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={() => gotoWarehouseConfig(row)}
                  >
                    <i className="mdi mdi-email-edit-outline text-primary me-1" />{" "}
                    Warehouse configuration
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        },
      ],
      []
    )
  }

  const columns = getColumns()

  const handleLocationClick = () => {
    navigator
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      var data = {
        appt_sys_ref: "OPENDOCK",
      }

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(api_url + "locations/search", config)

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      setLocationData(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Col>
                <div className="float-start">
                  <h5 className="text-primary">
                    Location Configuration - OpenDock
                  </h5>
                </div>
              </Col>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <LocationConfigTableContainer
                columns={columns}
                data={locationData}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={50}
                loading={loading}
                refreshTable={loadData}
                totalRows={locationData.length}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LocationConfiguration
