import React, { useContext, useEffect, useState } from "react"
import { Card, CardBody } from "reactstrap"
import axios from "axios"
import moment from "moment"
import Cookies from "js-cookie"

function CredAudit({ appt_sys }) {
  const [loading, setLoading] = useState(false)
  const [audits, setAudits] = useState([])
  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    let obj = {
      m_key: appt_sys.m_key,
    }
    console.log("Object in uipdate", obj)
    setLoading(true)
    try {
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      const response = await axios.post(api_url + "/appt-sys/audits", obj, {
        headers,
      })

      if (response.status >= 200 && response.status < 300) {
        setAudits(response.data)
        setLoading(false)
        console.log(audits)
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const parseDate = datestr => {
    let date = moment(datestr).format("MM-DD-yyyy @ hh:mm A")
    return date
  }

  console.log(audits)

  return (
    <Card>
      <CardBody>
        <h5 className="text-primary">Audit History</h5>
        {loading && <div>Loading....</div>}
        {!loading && audits.length > 0 && (
          <ul className="verti-timeline list-unstyled">
            {audits.map((audit, index) => (
              <li key={index} className="event-list m-0 pb-1 pl-20">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle text-primary" />
                </div>

                <div className="flex-grow-1">
                  <div className="mb-1">
                    <h6 className="text-primary">{audit.action_type}</h6>
                    <p className="text-muted mb-0">
                      {parseDate(audit.created_at)}
                    </p>
                    <p className="text-muted mb-0">
                      {audit.created_by} : {audit.ip_addr}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </CardBody>
    </Card>
  )
}

export default CredAudit
