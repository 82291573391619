import React, { useEffect, useState } from "react"
import { Auth } from 'aws-amplify'
import ResetPasswordForm from "./ResetPasswordForm"
import LoginForm from "./LoginForm"
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import jwt_decode from "jwt-decode";

function QuedLogin() {
    const [isFirstLogin, setIsFirstLogin] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [hasError, setHasError] = useState(false)
    const [user, setUser] = useState()
    const navigate = useNavigate()

    const qued_portal_url = process.env.REACT_APP_QUED_PORTAL_URL

    //meta title
    document.title = "Login"

    useEffect(() => {
        if (Cookies.get("email") && isTokenValid()) {
     
          navigate("/dashboard")
      
        }

    }, [])

    const isTokenValid = () => {
    let token = Cookies.get('idToken')
    if (token) {
      var decodedToken=jwt_decode(token, {complete: true});
      console.log("decoded token = " + decodedToken)
      var dateNow = new Date();
  
      console.log(decodedToken.exp * 1000 + "   " + dateNow.getTime())
      
      if(decodedToken.exp * 1000 < dateNow.getTime())
        return false

      return true;
    }
    return false
  }

    const [toast, setToast] = useState(false)

    const toggleToast = () => {
        setToast(!toast)
    }

    const handleLogin = async (username, password) => {
        try {
            const user = await Auth.signIn(username, password)
            setUser(user)

            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                const newPassword = prompt('Enter your new password');
                await Auth.completeNewPassword(user, newPassword);

            } 
            storeTokens(user)
        } catch (error) {
            console.error("Login failed:", error)
            setHasError(true)
            setErrorMessage('Invalid Credentials.')
        }
    }

    const handleResetPassword = async (newPassword) => {
        try {

            await Auth.completeNewPassword(user, newPassword);
            toggleToast()
            setIsFirstLogin(false)

        } catch (error) {
            console.error('Error resetting password:', error);
        }

    }

    const storeTokens = async (user) => {
        try {
            const session = await Auth.currentSession();
            const idToken = session.getIdToken().getJwtToken();
            const payload = session.getIdToken().payload;
            const accessToken = session.getAccessToken().getJwtToken();
            Cookies.set('email', payload['email'])
            Cookies.set('user', payload.given_name)
            localStorage.setItem('email', payload['email'])

            Cookies.set('idToken', idToken, { secure: true, sameSite: 'Strict', httpOnly: false });
            Cookies.set('accessToken', accessToken, { secure: true, sameSite: 'Strict', httpOnly: true });
            Cookies.set('user', payload.given_name)
            Cookies.set('role', payload['custom:role'])
            Cookies.set('tenantId', payload['custom:tenant'])

            console.log('Login successful!');
            var redirectUrl = localStorage.getItem("redirectUrl")
            clearCognitoCookies();

            console.log("redirect url " + redirectUrl)
            if (redirectUrl) {
                localStorage.removeItem("redirectUrl")
                navigate(redirectUrl)
            } else {
                navigate("/dashboard")
            }
        } catch (error) {
            console.error('Error storing tokens:', error);
        }
    }

    return (
        <>
            <div>
                {isFirstLogin ? (
                    <ResetPasswordForm
                        changePassword={handleResetPassword}
                        errorMessage={errorMessage}
                        hasError={hasError}
                    />
                ) : (
                    <LoginForm
                        login={handleLogin}
                        errorMessage={errorMessage}
                        hasError={hasError}
                    />
                )}
            </div>
            <div
                className="position-fixed top-0 end-0 p-3"
                style={{ zIndex: "1005" }}
            >
                <Toast isOpen={toast}>
                    <ToastHeader toggle={toggleToast}>
                        <img alt="" className="me-2 text-success" height="18" />
                        Success
                    </ToastHeader>
                    <ToastBody className="text-success ">
                        Password reset successfully, Please login with new password
                    </ToastBody>
                </Toast>
            </div>
        </>
    )

    function clearCognitoCookies() {
        console.log("clearing cognito cookies")
        const allCookies = Cookies.get();
        Object.keys(allCookies).forEach((c) => {
            if (c.startsWith('CognitoIdentityServiceProvider')) {
                Cookies.remove(c, { path: '/', domain: window.location.hostname, secure: true });
            }
        });

        console.log(Cookies.get())
    }
}

export default QuedLogin
