
import Cookies from 'js-cookie';
import jsonData from './aws-config-data.json'

let awsconfig
const qued_portal_url = process.env.REACT_APP_QUED_PORTAL_URL
console.log("Portal url : " + qued_portal_url)

const CookieStorage = {
    setItem: (key, value) => Cookies.set(key, value, { expires: 1, secure: false, sameSite: 'Strict' }),
    getItem: (key) => Cookies.get(key),
    removeItem: (key) => Cookies.remove(key),
    domain: 'localhost'
};

const loadAwsCognitoConfig = async () => {
    console.log(qued_portal_url === 'localhost')
    if (!awsconfig) {
        try {
            awsconfig = {
                Auth: {
                    region: jsonData.aws_cognito.region, // Your AWS Region
                    userPoolId: jsonData.aws_cognito.userPoolId, // Cognito User Pool ID
                    userPoolWebClientId: jsonData.aws_cognito.clientId, // App Client ID
                    mandatorySignIn: true,
                    cookieStorage: {
                        domain: qued_portal_url,
                        expires: 1, // Number of days until cookies expire
                        secure:  true, // Use true for HTTPS
                        sameSite: 'strict',
                        httpOnly: false
                    },
                }
            }

          } catch (error) {
            console.error('Error loading AWS config:', error);
            throw new Error('Failed to load AWS Amplify configuration.');
          }
    }

    return awsconfig
}





export default loadAwsCognitoConfig;