import React, { useEffect, useState } from "react"
import { Badge, Button, Col, Label, Modal, Row, Table } from "reactstrap"
import ValidationForm from "./ValidationForm"
import { Tbody, Thead } from "react-super-responsive-table"

const ValidationData = props => {
  const [validations, setValidations] = useState(props.validations || [])
  const [showAddForm, setShowAddForm] = useState(false)
  const [editingVal, setEditingVal] = useState(null)
  const [editingValIndex, setEditingValIndex] = useState(null)
  const [vars, setVars] = useState(props.vars || [])

  const addVal = () => {
    setEditingVal(null)
    setEditingValIndex(null)
    setShowAddForm(true)
  }

  const togAddForm = () => {
    setShowAddForm(!showAddForm)
  }

  const addNewVal = val => {
    if (editingVal) {
      var vals = [...validations]
      vals[editingValIndex] = val
      setValidations(vals)
      props.setValidations(vals)
    } else {
      if (validations?.length > 0) {
        var newVals = [...validations]
        newVals.push(val)
        setValidations(newVals)
        props.setValidations(newVals)
      } else {
        var vals = []
        vals.push(val)
        setValidations(vals)
        props.setValidations(vals)
      }
    }

    setEditingVal(null)
    setEditingValIndex(null)
    togAddForm()
  }

  useEffect(() => {
    console.log(validations)
    setEditingVal(editingVal)
  }, [editingVal])

  const editVal = (val, index) => {
    setEditingVal(val)
    setEditingValIndex(index)
    setShowAddForm(true)
  }

  const removeVal = index => {
    var vals = validations?.filter((item, idx) => idx !== index)
    setValidations(vals)
    props.setValidations(vals)
  }

  return (
    <>
      <Modal isOpen={showAddForm} toggle={togAddForm}>
        <ValidationForm
          closeModal={togAddForm}
          onSave={addNewVal}
          valData={editingVal}
          vars={vars}
          isNew={editingVal == null}
        />
      </Modal>
      <Row>
        <Label htmlFor="desc" className="col-sm-2 col-form-label">
          Validations
        </Label>
        <Col md={10}>
          {(!validations || validations.length == 0) && !props.isEdit && (
            <div className="text-muted">{"No validation added."}</div>
          )}
          {validations?.length > 0 && (
            <Table style={{ border: "none" }}>
              <Thead>
                <tr>
                  <th style={{ border: "none" }}>Validation Type</th>
                  <th style={{ border: "none" }}>Name</th>
                  <th style={{ border: "none" }}>Var</th>
                  <th style={{ border: "none" }}>Value</th>

                  <th style={{ border: "none" }}>Message</th>
                  {props.isEdit && <th style={{ border: "none" }}>Action</th>}
                </tr>
              </Thead>
              <Tbody>
                {validations.map((val, index) => (
                  <tr key={index}>
                    <td style={{ border: "none" }}>
                      {val.valdn_type}
                      {"enabled" in val && !val.enabled && (
                        <Badge className="m-1">disabled</Badge>
                      )}
                    </td>
                    <td style={{ border: "none" }}>{val.name}</td>
                    <td style={{ border: "none" }}>{val.var}</td>
                    <td style={{ border: "none" }}>{val.value}</td>
                    <td style={{ border: "none" }}>{val.message}</td>
                    {props.isEdit && (
                      <td style={{ border: "none" }}>
                        <i
                          className="mdi mdi-pencil font-size-16 text-success me-1 pointer"
                          onClick={() => editVal(val, index)}
                        />{" "}
                        {val.ten_level && (
                          <i
                            className="mdi mdi-trash-can font-size-16 text-danger me-1"
                            onClick={() => removeVal(index)}
                          />
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </Tbody>
            </Table>
          )}

          {props.isEdit && (
            <Button
              type="button"
              className="btn, btn-sm"
              color="primary"
              onClick={addVal}
            >
              Add
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}

export default ValidationData
