import Success from "pages/NotificationModal/Success"
import React, { useEffect, useState } from "react"
import LoadingOverlay from "react-loading-overlay"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap"
import TemplateValidations from "./TemplateValidations"
import TemplateVars from "./TemplateVars"
import Cookies from "js-cookie"

const EditForm = props => {
  const [variables, setVariables] = useState(props.data?.vars || [])
  const [templateName, setTemplateName] = useState()
  const [desc, setDesc] = useState()
  const [subjectHtml, setSubjectHtml] = useState()
  const [contentHtml, setContentHtml] = useState()
  const [safeContentHtml, setSafeContentHtml] = useState()
  const [safeSubjectHtml, setSafeSubjectHtml] = useState()
  const [loading, setLoading] = useState(false)
  const [validations, setValidations] = useState(props.data?.validations || [])

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (props.isClone) {
      setTemplateName("")
      setDesc("")
    } else {
      setTemplateName(props.data.name)
      setDesc(props.data.desc)
    }

    const body = props.data.content_body
    const sub = props.data.content_subject

    setContentHtml(atob(body))
    setSafeContentHtml(atob(body))
    setSubjectHtml(atob(sub))
    setSafeSubjectHtml(atob(sub))
  }, [])

  const togSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal)
  }

  const api_url = process.env.REACT_APP_API_URL

  const navigate = useNavigate()

  const handleTemplateNameChange = event => {
    const { name, value } = event.target
    setTemplateName(value)
  }

  const handleDescChange = event => {
    const { name, value } = event.target
    setDesc(value)
  }

  const close = () => {
    setShowSuccessModal(false)
    navigate("/email-templates")
  }

  const save = async () => {
    const encoder = new TextEncoder()
    const uint8ArraySubject = encoder.encode(safeSubjectHtml)
    const base64StringSubject = btoa(String.fromCharCode(...uint8ArraySubject))

    const uint8ArrayContent = encoder.encode(safeContentHtml)
    const base64StringContent = btoa(String.fromCharCode(...uint8ArrayContent))

    var obj = {
      name: templateName,
      desc: desc,
      content_body: base64StringContent,
      content_subject: base64StringSubject,
      vars: variables,
      validations: validations,
    }

    try {
      setLoading(true)

      const token = Cookies.get("idToken")

      const config = {
        method: props.isClone ? "POST" : "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      }

      var url = api_url + "email/templates/"
      if (!props.isClone) {
        url = url + templateName
      }
      const response = await fetch(url, config)

      if (!response.ok) {
        throw new Error("Request failed")
      }
      try {
        const jsonData = await response.json()
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
      setShowSuccessModal(true)
      setLoading(false)
      console.log("Saved")
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    setErrors(null)
    const validationErrors = validate()
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      setLoading(false)
    } else {
      setErrors(null)
      save()
    }
  }
  const validate = () => {
    const newErrors = {}
    const regex = /^[a-zA-Z0-9_ .]*$/

    if (!templateName) {
      newErrors.templateName = "Template name is required."
    } else if (!regex.test(templateName)) {
      newErrors.templateName =
        "Template name is invalid, only alpha numeric characters with space or underscore is allowed"
    }

    if (!desc) {
      newErrors.desc = "Description is required."
    } else if (!regex.test(desc)) {
      newErrors.desc =
        "Description is invalid, only alpha numeric characters with space or underscore is allowed"
    }

    if (!variables || variables.length == 0) {
      newErrors.variables = "Please add required variables."
    }

    // if (subjectHtml !== safeSubjectHtml) {
    //   newErrors.subjectHtml = "Invalid script removed from the subject."
    // }

    return newErrors
  }

  const removeVar = index => {
    const newItems = [...formdata]
    newItems.splice(index, 1)
    setFormdata(newItems)
  }

  const handleCancel = () => {
    props.cancel()
  }

  const sanitizeHTML = inputHTML => {
    // Remove any tag that contains attributes starting with "on" or any potentially dangerous attribute
    let sanitizedHTML = inputHTML.replace(
      ///<[^>]*(\s|\/)?on[a-zA-Z]*[^>]*>[^<]*<\/[^>]*>|<[^>]*(\s|\/)?on[a-zA-Z]*[^>]*\/?>/gi,
      /<script[^>]*>[\s\S]*?<\/script>|<[^>]*(\s|\/)?on[a-zA-Z]*[^>]*>[^<]*<\/[^>]*>|<[^>]*(\s|\/)?on[a-zA-Z]*[^>]*\/?>/gi,
      ""
    )

    // Remove <script> tags entirely
    const noScriptHTML = sanitizedHTML.replace(
      /<script[^>]>[\s\S]?<\/script>/gi,
      ""
    )

    return noScriptHTML
  }

  const handleBodyContentChange = e => {
    const input = e.target.value

    setContentHtml(input)
    let safeContent = sanitizeHTML(input)

    setSafeContentHtml(safeContent)
    console.log(safeContent)
  }

  const handleSubjectContentChange = e => {
    const input = e.target.value

    setSubjectHtml(input)
    let safeContent = sanitizeHTML(input)

    setSafeSubjectHtml(safeContent)
  }

  return (
    <>
      <Modal
        isOpen={showSuccessModal}
        toggle={() => {
          togSuccessModal()
        }}
        centered
        size="md"
      >
        <Success
          message="Template configuration saved successfully !!"
          close={close}
        />
      </Modal>
      <Row>
        <LoadingOverlay active={loading} spinner>
          <Card>
            <CardBody>
              {props.isClone && (
                <CardTitle className="mb-4 text-primary">
                  Create New Email Template 1
                </CardTitle>
              )}
              {!props.isClone && (
                <CardTitle className="mb-4 text-primary">
                  Update New Email Template
                </CardTitle>
              )}
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleSubmit()
                  return false
                }}
              >
                <Card outline color="primary" className="border mt-3 mb-3">
                  <CardBody>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-2 col-form-label"
                      >
                        Name *
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          className="form-control"
                          name="name"
                          id="horizontal-firstname-Input"
                          placeholder="Enter name of template"
                          onChange={e => {
                            e.preventDefault()
                            handleTemplateNameChange(e)
                          }}
                          value={templateName}
                          disabled
                          invalid={errors?.templateName ? true : false}
                        />
                        {errors?.templateName && (
                          <FormFeedback type="invalid">
                            {errors.templateName}
                          </FormFeedback>
                        )}
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label htmlFor="desc" className="col-sm-2 col-form-label">
                        Description *
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          className="form-control"
                          name="desc"
                          id="horizontal-firstname-Input"
                          placeholder="Enter description of template"
                          onChange={handleDescChange}
                          value={desc}
                          invalid={errors?.desc ? true : false}
                        />
                        {errors?.desc && (
                          <FormFeedback type="invalid">
                            {errors.desc}
                          </FormFeedback>
                        )}
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <TemplateVars
                        vars={variables}
                        setVars={setVariables}
                        isNew={false}
                        isEdit={true}
                      />
                    </Row>
                  </CardBody>
                </Card>
                <Card outline color="primary" className="border mt-3 mb-3">
                  <CardBody>
                    <Row>
                      <TemplateValidations
                        setValidations={setValidations}
                        validations={validations}
                        isEdit={true}
                      />
                    </Row>
                  </CardBody>
                </Card>
                <Card outline color="primary" className="border mt-3 mb-3">
                  <CardBody>
                    <Row className="mb-4">
                      <Label htmlFor="desc" className="col-sm-2 col-form-label">
                        Subject Content
                      </Label>
                      <Col sm={6}>
                        <Input
                          value={subjectHtml}
                          type="text"
                          id="textarea"
                          onChange={handleSubjectContentChange}
                          placeholder="This textarea has a limit of 225 chars."
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label htmlFor="desc" className="col-sm-2 col-form-label">
                        Body content
                      </Label>
                      <Col sm={6}>
                        <Input
                          value={contentHtml}
                          type="textarea"
                          id="textarea"
                          style={{ height: "400px" }}
                          onChange={handleBodyContentChange}
                          placeholder="This textarea has a limit of 225 chars."
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <div className="modal-footer">
                  <Button
                    color="primary"
                    className="btn btn-primary me-1"
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    color="secondary"
                    className="btn btn-secondary"
                    type="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </LoadingOverlay>
      </Row>
    </>
  )
}

export default EditForm
