import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import ViewBaseConfig from "./ViewBaseConfig"
import Cookies from "js-cookie"

const OpenDockBaseConfig = props => {
  const [baseConfig, setBaseConfig] = useState(null)
  const [loading, setLoading] = useState(false)
  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    getBaseConfig()
  }, [])

  const getBaseConfig = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")

      const config = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      const response = await fetch(
        api_url + "locations/config/base/" + props.locationId,
        config
      )

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      if (jsonData) {
        setBaseConfig(jsonData?.loc_base_config)
      }
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  return (
    <>
      <ViewBaseConfig
        data={baseConfig}
        locationId={props.locationId}
        refresh={getBaseConfig}
        allowEdit={props.allowEdit}
      ></ViewBaseConfig>
    </>
  )
}

export default OpenDockBaseConfig
