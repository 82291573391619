import Select from "react-select"
import React, { useEffect, useState } from "react"
import LoadingOverlay from "react-loading-overlay"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap"
import Cookies from "js-cookie"

const LocationMappingForm = props => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [success, setSuccess] = useState(false)
  const [locationId, setLocationId] = useState(props.locId)
  const [qdLocationId, setQdLocationId] = useState("")
  const [locationName, setLocationName] = useState("")
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [selectedGroup, setselectedGroup] = useState(null)

  const api_url = process.env.REACT_APP_API_URL

  const navigate = useNavigate()

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  useEffect(() => {
    searchLocatoin("")
  }, [])

  const searchLocatoin = async val => {
    setLoading(true)
    try {
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      var url = api_url + "locations"
      console.log(url)
      const response = await fetch(url, { headers })
      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        setHasError(true)
        const res = await response.json()
        setErrorMessage(res.message)
        setLoading(false)
        throw new Error(res.message)
      }
      const jsonData = await response.json()

      setData(jsonData)

      setLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setLoading(false)
      console.error(error.message)
    }
  }

  const create = async () => {
    setFormSubmitting(true)
    try {
      const token = Cookies.get("idToken")
      var url = api_url

      url = url + "locations"

      console.log(props.row)

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tmsLocationId: locationId,
          qdLocationId: qdLocationId,
          locationName: locationName,
        }),
      }

      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        setErrorMessage(res.message)
        setFormSubmitting(false)
        throw new Error(res.message)
      }
      setErrorMessage(null)
      setFormSubmitting(false)
      props.successResult()
    } catch (error) {
      console.error("Error:" + error.message)
      setFormSubmitting(false)
      console.error(error.message)
    }
  }

  const handleChange = val => {
    console.log(val.target.value)
    var obj = data.filter(item => item.tLocationName === val.target.value)

    console.log(obj)
    if (obj && obj[0]) {
      setQdLocationId(obj[0].quedLocationId)
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <LoadingOverlay active={formSubmitting} spinner>
            <h3 className="text-primary d-flex justify-content-center">
              Add Location Mapping
            </h3>
            <Row className="mb-4 mt-4">
              <Label
                htmlFor="locationId-input"
                className="col-sm-3 col-form-label"
              >
                Location Id
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  className="form-control"
                  id="locationId-input"
                  placeholder="Enter Your"
                  value={props.locId}
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-4 mt-4">
              <Label
                htmlFor="locationId-input"
                className="col-sm-3 col-form-label"
              >
                Location Name
              </Label>
              <Col sm={9}>
                <Input
                  className="form-control"
                  list="datalistOptions"
                  id="exampleDataList"
                  placeholder="Type to search..."
                  onChange={e => handleChange(e)}
                />
                <datalist id="datalistOptions">
                  {loading && <option>searching.</option>}
                  {data &&
                    data.map((item, index) => {
                      return (
                        <option key={index} data={item}>
                          {item.tLocationName}
                        </option>
                      )
                    })}
                </datalist>
                {errorMessage && (
                  <Col sm={9}>
                    <span className="text-red">{errorMessage}</span>
                  </Col>
                )}
              </Col>
            </Row>

            <Row>
              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  className="btn btn-primary"
                  onClick={() => create()}
                  disabled={qdLocationId === ""}
                >
                  Save
                </Button>
                &nbsp;&nbsp;
                <Button
                  color="secondary"
                  className="btn btn-secondary"
                  onClick={() => navigate("/location-management")}
                >
                  Cancel
                </Button>
              </div>
            </Row>
          </LoadingOverlay>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default LocationMappingForm
