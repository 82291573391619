import QdContainer from "components/Common/QdContainer"
import Success from "pages/NotificationModal/Success"
import React, { useEffect, useState } from "react"
import LoadingOverlay from "react-loading-overlay"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap"
import Cookies from "js-cookie"

const SAMLEditForm = () => {
  const [loading, setLoading] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [tenant, setTenant] = useState()

  const api_url = process.env.REACT_APP_API_URL

  const navigate = useNavigate()

  useEffect(() => {
    loadTenantData()
  }, [])

  const loadTenantData = async () => {
    try {
      setLoading(true)
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      var url = api_url

      url = url + "tenant"

      const response = await fetch(url, { headers })

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      setTenant(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setLoading(false)
      console.error(error)
    }
  }

  const cancel = () => {
    navigate("/auth-config/code")
  }

  const save = async values => {
    setLoading(true)
    console.log(values)
    try {
      const token = Cookies.get("idToken")
      var url = api_url

      url = url + "idp/saml"
      var provider_name =
        tenant.shortname.replace(/_/g, "-") + "-" + values.provider_type
      var clientName =
        tenant.shortname.replace(/_/g, "-") +
        "-" +
        values.provider_type +
        "-client"

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          provider_name: provider_name,
          provider_type: values.provider_type,
          metadata_url: values.metadata_url,
          client_name: clientName,
          logout_url: values.logout_url,
        }),
      }
      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        setError(res.error)
        setLoading(false)
        throw new Error(res.error)
      }
      setLoading(false)
      setShowSuccessModal(true)
    } catch (error) {
      console.error("Error:" + error.message)
      console.error(error.message)
      setLoading(false)
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      provider_type: "SAML",
      metadata_url: "",
      logout_url: "",
    },
    validationSchema: Yup.object({
      metadata_url: Yup.string().required("Please Enter Issuer URL"),
    }),

    onSubmit: values => {
      save(values)
    },
  })

  const togSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal)
    navigate("/auth-config/code")
  }

  return (
    <QdContainer>
      <div>
        <Modal
          isOpen={showSuccessModal}
          toggle={() => {
            togSuccessModal()
          }}
          centered
          size="sm"
        >
          <Success
            message="Identity provider created successfully"
            close={togSuccessModal}
          />
        </Modal>
        <Card>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <CardBody className="p-4">
              <LoadingOverlay active={loading} spinner>
                <h5 className="mb-4 text-primary">
                  Create New Identity Provider
                </h5>

                <Row className="mb-4">
                  <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-3 col-form-label"
                  >
                    Provider Type
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      className="form-control"
                      name="provider_type"
                      id="horizontal-firstname-Input"
                      placeholder="Enter the name of provider"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.provider_type}
                      disabled
                      invalid={
                        validation.touched.provider_type &&
                        validation.errors.provider_type
                          ? true
                          : false
                      }
                    />
                    {validation.touched.provider_type &&
                    validation.errors.provider_type ? (
                      <FormFeedback type="invalid">
                        {validation.errors.provider_type}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-3 col-form-label"
                  >
                    Saml Metadata URL
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      className="form-control"
                      name="metadata_url"
                      id="horizontal-firstname-Input"
                      placeholder="Enter the metadata URL eg https://okta.com"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.metadata_url}
                      invalid={
                        validation.touched.metadata_url &&
                        validation.errors.metadata_url
                          ? true
                          : false
                      }
                    />
                    {validation.touched.metadata_url &&
                    validation.errors.metadata_url ? (
                      <FormFeedback type="invalid">
                        {validation.errors.metadata_url}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-3 col-form-label"
                  >
                    Logout URL
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      className="form-control"
                      name="logout_url"
                      id="horizontal-firstname-Input"
                      placeholder="Enter the Logout URL eg https://okta.com"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.logout_url}
                      invalid={
                        validation.touched.logout_url &&
                        validation.errors.logout_url
                          ? true
                          : false
                      }
                    />
                    {validation.touched.logout_url &&
                    validation.errors.logout_url ? (
                      <FormFeedback type="invalid">
                        {validation.errors.logout_url}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>

                <Row className="d-flex justify-content-end">
                  <Col sm={9}>
                    <div>
                      <Button type="submit" color="primary" className="w-md">
                        Submit
                      </Button>
                      {"     "}
                      <Button
                        type="button"
                        color="secondary"
                        className="w-md"
                        onClick={() => {
                          cancel()
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Row>
              </LoadingOverlay>
            </CardBody>
          </Form>
        </Card>
      </div>
    </QdContainer>
  )
}

export default SAMLEditForm
