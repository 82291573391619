import React, { useEffect, useState } from "react"
import LoadingOverlay from "react-loading-overlay"
import { Link } from "react-router-dom"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteUserBox from "./DeleteUserBox"
import DisableUserBox from "./DisableUserBox"
import EnableUserBox from "./EnableUserBox"
import ResendUserPassword from "./ResendUserPassword"
import Cookies from "js-cookie"

const UserCard = props => {
  const user = props.user
  const [singlebtn, setSinglebtn] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDisableModal, setShowDisableModal] = useState(false)
  const [showEnableModal, setShowEnableModal] = useState(false)
  const [showResendModal, setShowResendModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const api_url = process.env.REACT_APP_API_URL

  function tog_center(status) {
    setShowModal(!showModal)
  }

  const openDelete = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const openDisable = () => {
    setShowDisableModal(!showDisableModal)
  }

  const openEnable = () => {
    setShowEnableModal(!showEnableModal)
  }

  const openResend = () => {
    setShowResendModal(!showResendModal)
  }

  const update = async action => {
    setLoading(true)
    try {
      const token = Cookies.get("idToken")
      var url = api_url

      url = url + "users/" + action

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.attributes.email,
        }),
      }
      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        setLoading(false)
        throw new Error(res.message)
      }
      setShowDisableModal(false)
      setShowEnableModal(false)
      setLoading(false)
      props.refreshUsers()
    } catch (error) {
      console.error("Error:" + error.message)
      console.error(error.message)
    }
  }

  const disable = async () => {
    update("disable")
  }

  const enable = async () => {
    update("enable")
  }

  const remove = async () => {
    setLoading(true)
    try {
      const token = Cookies.get("idToken")
      var url = api_url

      url = url + "users"

      const config = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.attributes.email,
        }),
      }
      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        setLoading(false)
        throw new Error(res.message)
      }
      setShowDeleteModal(false)
      setLoading(false)
      props.refreshUsers()
    } catch (error) {
      console.error("Error:" + error.message)
      console.error(error.message)
    }
  }

  const resend = async () => {
    setLoading(true)
    try {
      const token = Cookies.get("idToken")
      var url = api_url

      url = url + "users/resend"

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.attributes.email,
          firstName: user.attributes.given_name,
          lastName: user.attributes.family_name,
        }),
      }
      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        setLoading(false)
        throw new Error(res.message)
      }
      setShowDisableModal(false)
      setShowEnableModal(false)
      setLoading(false)
      props.refreshUsers()
    } catch (error) {
      console.error("Error:" + error.message)
      console.error(error.message)
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showDeleteModal}
        toggle={() => {
          tog_center(null)
        }}
        centered
        size="md"
      >
        <DeleteUserBox
          setShowDeleteModal={setShowDeleteModal}
          loading={loading}
          remove={remove}
          setLoading={setLoading}
          user={user}
        />
      </Modal>
      <Modal
        isOpen={showDisableModal}
        toggle={() => {
          tog_center(null)
        }}
        centered
        size="md"
      >
        <DisableUserBox
          setShowDisableModal={setShowDisableModal}
          loading={loading}
          disable={disable}
          setLoading={setLoading}
          user={user}
        />
      </Modal>
      <Modal
        isOpen={showEnableModal}
        toggle={() => {
          tog_center(null)
        }}
        centered
        size="md"
      >
        <EnableUserBox
          setShowEnableModal={setShowEnableModal}
          loading={loading}
          enable={enable}
          setLoading={setLoading}
          user={user}
        />
      </Modal>
      <Modal
        isOpen={showResendModal}
        toggle={() => {
          tog_center(null)
        }}
        centered
        size="md"
      >
        <ResendUserPassword
          setShowResendModal={setShowResendModal}
          loading={loading}
          resend={resend}
          setLoading={setLoading}
          user={user}
        />
      </Modal>
      <Col xl={3}>
        <div className="mb-4"></div>
        <Card>
          <CardBody>
            <div className="d-flex">
              <div className="avatar-md me-3">
                <span
                  className={
                    "avatar-title rounded-circle bg-soft bg-primary" +
                    " text-primary" +
                    " font-size-16"
                  }
                >
                  {user.attributes.given_name.charAt(0)}
                  {user.attributes.family_name &&
                    user.attributes.family_name.charAt(0)}
                </span>
              </div>

              <div className="flex-grow-1 overflow-hidden">
                <h5 className="text-truncate font-size-15">
                  <span className="text-dark">
                    {user.attributes.given_name}&nbsp;
                    {user.attributes.family_name}
                  </span>
                </h5>
                <p className="text-muted mb-2">{user.attributes.email}</p>
                <p className="text-muted mb-2">
                  Email Verified{" "}
                  {user.attributes.email_verified && (
                    <i className="mdi mdi-check-circle text-success display-7" />
                  )}
                  {!user.attributes.email_verified && (
                    <i className="mdi mdi-close-circle text-danger display-7" />
                  )}
                </p>

                {user.enabled ? (
                  <p className="text-success mb-2">Active</p>
                ) : (
                  <p className="text-danger mb-2">Disabled</p>
                )}
              </div>
              {props.currentUserEmail != user.attributes.email && (
                <div>
                  <div className="float-end ms-1">
                    <Dropdown
                      className="mb-2 popup"
                      isOpen={singlebtn}
                      toggle={() => setSinglebtn(!singlebtn)}
                    >
                      <DropdownToggle
                        className="font-size-16 text-muted"
                        tag="a"
                      >
                        <i className="mdi mdi-dots-horizontal"></i>
                      </DropdownToggle>

                      <DropdownMenu className="dropdown-menu-end">
                        {user.enabled ? (
                          <DropdownItem href="#" onClick={() => openDisable()}>
                            Disable
                          </DropdownItem>
                        ) : (
                          <DropdownItem href="#" onClick={() => openEnable()}>
                            Enable
                          </DropdownItem>
                        )}
                        {user.user_status === "FORCE_CHANGE_PASSWORD" && (
                          <DropdownItem
                            href="#"
                            onClick={() => {
                              openResend()
                            }}
                          >
                            Resend Verification
                          </DropdownItem>
                        )}
                        <div className="dropdown-divider"></div>
                        <DropdownItem href="#" onClick={() => openDelete()}>
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              )}
            </div>
          </CardBody>
          <div className="px-4 py-3 border-top">
            <ul className="list-inline mb-0">
              <li className="list-inline-item me-3">
                <span
                  className={
                    user.user_status === "CONFIRMED"
                      ? "badge badge-soft-success"
                      : "badge badge-soft-warning"
                  }
                >
                  {user.user_status}
                </span>
              </li>{" "}
              <li className="list-inline-item me-3" id="createdOn">
                <i className="bx bx-calendar me-1" />{" "}
                {new Date(user.created_at).toDateString()}
                <UncontrolledTooltip placement="top" target="createdOn">
                  Created On
                </UncontrolledTooltip>
              </li>{" "}
            </ul>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default UserCard

// cloudwatch log...
