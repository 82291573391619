import JsonEditor from "pages/NewLocation/JsonEditor"
import Confirmation from "pages/NotificationModal/Confirmation"
import Success from "pages/NotificationModal/Success"
import React, { useEffect, useState } from "react"
import LoadingOverlay from "react-loading-overlay"
import { json } from "react-router-dom"
import { Button, Card, CardBody, Col, Modal, Row } from "reactstrap"
import Cookies from "js-cookie"

const Preview = props => {
  const [sampleJsonEvent, setSampleJsonEvent] = useState('{"abc":"def"}')
  const [loading, setLoading] = useState(false)
  const [subHtml, setSubHtml] = useState()
  const [bodyHtml, setBodyHtml] = useState()
  const [showConfirm, setShowConfirm] = useState(false)
  const [validationMessages, setValidationMessages] = useState()
  const [isEdit, setIsEdit] = useState(false)

  const api_url = process.env.REACT_APP_API_URL

  const handleInputChange = () => {
    const { value } = event.target
    setSampleJsonEvent(value)
  }

  useEffect(() => {
    fetch("/sampleEvent.json")
      .then(response => {
        if (response.ok) {
          return response.text()
        }
      })
      .then(text => setSampleJsonEvent(JSON.parse(text)))
      .catch(error => console.log(error))
  }, [])

  const validate = () => {
    try {
      const parsedData = JSON.parse(sampleJsonEvent)
      console.log(parsedData) // Do something with parsed data
    } catch (error) {
      console.error("Error parsing JSON:", error)
    }
  }

  const generatePreview = async () => {
    validate()

    try {
      setLoading(true)
      const token = Cookies.get("idToken")

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sampleJsonEvent: sampleJsonEvent,
        }),
      }
      const response = await fetch(
        api_url +
          "email/templatemapping/" +
          props.m_loc_id +
          "/preview?cfgType=" +
          props.cfgType,
        config
      )

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonresp = await response.json()
      if (jsonresp) {
        console.log(jsonresp)
        if (jsonresp.content?.body) {
          var body = atob(jsonresp.content.body).replace(
            "table, th, td {",
            ".appt_details_tb table, .appt_details_tb th, .appt_details_tb td {"
          )
          console.log(body)
          setBodyHtml(body)
          //setBodyHtml(atob(jsonresp.content.body))
        }
        if (jsonresp.content?.subject) {
          setSubHtml(atob(jsonresp.content.subject))
        }

        if (jsonresp.validation_messages) {
          setValidationMessages(jsonresp.validation_messages)
        }
      }
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const confirmPublish = () => {
    setShowConfirm(true)
  }

  const toggleConfirmModal = () => {
    setShowConfirm(!showConfirm)
  }

  const publish = async () => {
    props.publish()
  }

  const closeConfirm = () => {
    setShowConfirm(false)
  }

  const updateSampleEventJson = data => {
    setSampleJsonEvent(data)
  }

  return (
    <>
      <Modal
        isOpen={showConfirm}
        toggle={() => {
          toggleConfirmModal()
        }}
        centered
        size="md"
      >
        <Confirmation
          message={"Are you sure you want to publish this template ?"}
          confirm={publish}
          cancel={closeConfirm}
        />
      </Modal>
      <Row>
        <Col className="p-0">
          <Card outline color="primary" className="border mt-3 mb-3">
            <CardBody>
              <span className="text-primary pb-4">Sample Json </span>
              {/* <textarea
                            className="form-control"
                            rows={10}
                            cols={50}
                            value={sampleJsonEvent}
                            onChange={handleInputChange}
                            placeholder="Enter JSON data here..."
                        /> */}
              <JsonEditor
                setSampleJsonEvent={updateSampleEventJson}
                data={sampleJsonEvent}
                setIsEdit={setIsEdit}
              />
              <br />
              {/* <Button onClick={handleParseJson} color="primary">Parse JSON</Button> */}
              {!isEdit && (
                <Button
                  type="button"
                  className="btn btn-soft-primary btn-sm waves-effect waves-light"
                  onClick={generatePreview}
                >
                  Preview
                </Button>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col className="pl-2">
          <LoadingOverlay active={loading} spinner>
            <Card outline color="primary" className="border mt-3 mb-3">
              <CardBody>
                <h6 className="text-primary">Preview</h6>
                {!bodyHtml && !subHtml && (
                  <div className="d-flex p-4 justify-content-center text-primary">
                    No preview available.
                  </div>
                )}
                {bodyHtml && subHtml && (
                  <>
                    <div
                      dangerouslySetInnerHTML={{ __html: subHtml }}
                      style={{
                        border: "1px solid red",
                        padding: "10px",
                        margin: "10px",
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{ __html: bodyHtml }}
                      style={{
                        border: "1px solid red",
                        padding: "10px",
                        margin: "10px",
                      }}
                    />
                  </>
                )}

                {validationMessages?.length > 0 && (
                  <ul>
                    <span className="text-primary">{"Validation Message"}</span>
                    {validationMessages.map((item, index) => (
                      <li key={index}>
                        {item.name} - {item.message}
                      </li>
                    ))}
                  </ul>
                )}
              </CardBody>
              {props.type == "staged" && (
                <div className="modal-footer p-3">
                  <Button
                    color="primary"
                    className="btn btn-sm"
                    onClick={confirmPublish}
                    disabled={!bodyHtml && !subHtml}
                  >
                    Publish
                  </Button>
                </div>
              )}
            </Card>
          </LoadingOverlay>
        </Col>
      </Row>
    </>
  )
}

export default Preview
