import { TextareaAutosize } from "@mui/material"
import QdContainer from "components/Common/QdContainer"
import React, { useEffect, useState } from "react"
import { Link, NavLink, useParams } from "react-router-dom"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
import classes from "./TemplateDetails.scss"
import classnames from "classnames"
import Preview from "./Preview"
import TemplateValidations from "./TemplateValidations"
import TemplateVars from "./TemplateVars"
import Cookies from "js-cookie"

const TemplateDetails = props => {
  const [loading, setLoading] = useState(false)
  const [templateData, setTemplateData] = useState(null)
  const [encodedContent, setEncodedContent] = useState()
  const [encodedSubject, setEncodedSubject] = useState()
  const [contentHtml, setContentHtml] = useState()
  const [subjectHtml, setSubjectHtml] = useState()

  const [customActiveTab, setcustomActiveTab] = useState("1")

  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    fetchData()
  }, [])

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const fetchData = async () => {
    const name = props.name
    setLoading(true)
    try {
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      var url = api_url

      url = url + "email/templates/" + name

      const response = await fetch(url, { headers })

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      setTemplateData(jsonData)
      setContentHtml(atob(jsonData.content_body))
      setSubjectHtml(atob(jsonData.content_subject))
      setLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setLoading(false)
      console.error(error)
    }
  }

  const edit = () => {}

  return (
    <>
      {loading && <div>Loading..</div>}
      {!loading && templateData && (
        <>
          {" "}
          <Card>
            <CardBody>
              <Card className="border mt-3 mb-3" outline color="primary">
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <Row className="mb-2">
                        <Col lg={2} className="text-muted">
                          Name
                        </Col>
                        <Col className="text-primary">{templateData.name}</Col>
                      </Row>
                      <Row className="mb-2">
                        <Col lg={2} className="text-muted">
                          {" "}
                          Description
                        </Col>
                        <Col>{templateData.desc}</Col>
                      </Row>
                      <Row className="mb-2">
                        <TemplateVars vars={templateData.vars} isEdit={false} />
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="border mt-3 mb-3" outline color="primary">
                <CardBody>
                  <TemplateValidations
                    validations={templateData?.validations}
                    isEdit={false}
                  />
                </CardBody>
              </Card>
              <Card className="border mt-3 mb-3" outline color="primary">
                <CardBody>
                  <Label className="text-muted">Subject content</Label>
                  <Input
                    value={subjectHtml}
                    type="text"
                    id="textarea"
                    // style={{ height: '100px' }}
                    onChange={e => {
                      setSubjectHtml(e.target.value)
                    }}
                    placeholder="This textarea has a limit of 225 chars."
                    readOnly
                  />
                  <br />
                  <Label className="mt-2">Body content</Label>
                  <Input
                    value={contentHtml}
                    type="textarea"
                    id="textarea"
                    style={{ height: "400px" }}
                    onChange={e => {
                      setContentHtml(e.target.value)
                    }}
                    readOnly
                    placeholder="This textarea has a limit of 225 chars."
                  />
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </>
      )}
    </>
  )
}

export default TemplateDetails
