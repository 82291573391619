import React from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Success from "pages/NotificationModal/Success"
import LoadingOverlay from "react-loading-overlay"
import { Tbody, td, Th, Thead, tr } from "react-super-responsive-table"

export function configurationValidationDetails(
  loading,
  validations,
  displayValidationEditModal,
  removeValidation,
  allowEdit
) {
  return (
    <Card outline color="primary" className="border">
      <CardBody>
        <CardTitle className="mt-0">
          <Row>
            <Col>
              <div className="float-start">
                {/* <h5 className="text-primary">{title}</h5> */}
                <h5 className="text-primary">Validations</h5>
              </div>
            </Col>
          </Row>
        </CardTitle>
        {loading && <div>Loading...</div>}
        {validations && validations.length > 0 && (
          <Row>
            <Table className="table table-bordered">
              <thead>
                <tr>
                  <Th>Key</Th>
                  <Th>Var</Th>
                  <Th>Validation Type</Th>
                  <Th>Value</Th>
                  <Th>Message</Th>
                  <Th>Action</Th>
                </tr>
              </thead>
              <tbody>
                {validations.map((data, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{data.key}</td>
                      <td>{data.var}</td>
                      <td>{data.valdn_type}</td>
                      <td>{data.value}</td>
                      <td>{data.message}</td>
                      <td>
                        {allowEdit && (
                          <>
                            <span>
                              {" "}
                              <i
                                className="bx bx-pencil pointer text-primary"
                                onClick={() =>
                                  displayValidationEditModal(idx, validations)
                                }
                              ></i>
                            </span>
                            <span>
                              {" "}
                              <i
                                className="fas fa-trash-alt pointer text-primary"
                                onClick={() =>
                                  removeValidation(idx, validations)
                                }
                              ></i>
                            </span>
                          </>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Row>
        )}
        {!validations || (validations.length == 0 && <div>No data found</div>)}
      </CardBody>
    </Card>
  )
}
export function openAddMappingModal(
  showAddMappingModal,
  toggleAddMappingModal,
  cancel,
  create
) {
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      value: "",
      type: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please enter the key"),
      value: Yup.string().required("Please enter the value"),
      type: Yup.string().required("Please select type"),
    }),

    onSubmit: values => {
      create(values)
    },
  })
  return (
    <Modal
      isOpen={showAddMappingModal}
      toggle={() => {
        toggleAddMappingModal()
      }}
      centered
      size="md"
    >
      <React.Fragment>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Mapping</h5>
        </div>

        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <Label className="form-label">Mapping Type</Label>
              <select
                className="form-control"
                name="type"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.type}
              >
                <option value="">Select</option>
                <option value="var_map">Variable Mapping</option>
                <option value="root_mapping">Root Mapping</option>
                <option value="loadType">Load Type</option>
                <option value="custom_field_mapping">
                  Custom Field Mapping
                </option>
              </select>
              {validation.touched.type && validation.errors.type ? (
                <FormFeedback type="invalid">
                  {validation.errors.type}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">Key</Label>
              <Input
                name="name"
                className="form-control"
                placeholder="Enter key of the configuration"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || ""}
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">Value</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  name="value"
                  value={validation.values.value || ""}
                  type="text"
                  placeholder="Enter value"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.value && validation.errors.value
                      ? true
                      : false
                  }
                />
              </div>
              {validation.touched.value && validation.errors.value ? (
                <FormFeedback type="invalid">
                  {validation.errors.value}
                </FormFeedback>
              ) : null}
            </div>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={cancel}
            >
              Cancel
            </button>
          </div>
        </Form>
      </React.Fragment>
    </Modal>
  )
}
export function openDeleteConfigModal(
  showDeleteConfirm,
  tog_center,
  loading,
  setShowDeleteConfirm,
  editingData,
  deleteConfig
) {
  return (
    <Modal
      isOpen={showDeleteConfirm}
      toggle={() => {
        tog_center(null)
      }}
      centered
      size="md"
    >
      <LoadingOverlay active={loading} spinner>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Confirmation</h5>
          <button
            type="button"
            onClick={() => {
              tog_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span
              aria-hidden="true"
              onClick={() => setShowDeleteConfirm(false)}
            >
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body">
          Are you sure you want to delete the mapping{" "}
          <span className="text-primary">
            {editingData?.data[editingData.index]?.name}
          </span>{" "}
          ?
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => deleteConfig()}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-secodnary"
            onClick={() => setShowDeleteConfirm(false)}
          >
            Cancel
          </button>
        </div>
      </LoadingOverlay>
    </Modal>
  )
}

export function openValidationDeletionModal(
  showDeleteConfirm,
  tog_center,
  loading,
  setShowDeleteConfirm,
  editingData,
  deleteConfig
) {
  return (
    <Modal
      isOpen={showDeleteConfirm}
      toggle={() => {
        tog_center(null)
      }}
      centered
      size="md"
    >
      <LoadingOverlay active={loading} spinner>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Confirmation</h5>
          <button
            type="button"
            onClick={() => {
              tog_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span
              aria-hidden="true"
              onClick={() => setShowDeleteConfirm(false)}
            >
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body">
          Are you sure you want to delete the validation{" "}
          <span className="text-primary">
            {editingData?.data[editingData.index]?.key}
          </span>{" "}
          ?
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => deleteConfig()}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-secodnary"
            onClick={() => setShowDeleteConfirm(false)}
          >
            Cancel
          </button>
        </div>
      </LoadingOverlay>
    </Modal>
  )
}

export function openSuccessModal(tog_center, cancel, showSuccessModal) {
  return (
    <Modal
      isOpen={showSuccessModal}
      toggle={() => {
        tog_center(null)
      }}
      centered
      size="sm"
    >
      <Success
        message={"Successfully updated the base configuration !!"}
        close={cancel}
      />
    </Modal>
  )
}
export function configurationDetails(
  displayEditModal,
  config,
  title,
  type,
  loading,
  remove,
  allowEdit
) {
  return (
    <Card outline color="primary" className="border">
      <CardBody>
        <CardTitle className="mt-0">
          <Row>
            <Col>
              <div className="float-start">
                <h5 className="text-primary">{title}</h5>
              </div>
            </Col>
          </Row>
        </CardTitle>
        {loading && <div>Loading...</div>}
        {config && config.length > 0 && (
          <div>
            {config.map((data, idx) => {
              return (
                <Row key={idx}>
                  <Col sm={4}>
                    <span className="text-dark fw-600"> {data.name}</span>
                  </Col>
                  <Col sm={4}>
                    <span className="text-muted">{data.value}</span>
                  </Col>
                  {allowEdit && (
                    <Col sm={3}>
                      {" "}
                      <span>
                        {" "}
                        <i
                          className="bx bx-pencil pointer text-primary"
                          onClick={() => displayEditModal(idx, type, config)}
                        ></i>
                      </span>
                      <span>
                        {" "}
                        <i
                          className="fas fa-trash-alt pointer text-primary"
                          onClick={() => remove(idx, type, config)}
                        ></i>
                      </span>
                    </Col>
                  )}
                </Row>
              )
            })}
          </div>
        )}
        {!config || (config.length == 0 && <div>No data found</div>)}
      </CardBody>
    </Card>
  )
}
export function openEditModal(
  showEditModal,
  tog_center,
  save,
  editingData,
  cancel
) {
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: editingData?.data[editingData.index]?.name,
      value: editingData?.data[editingData.index]?.value,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter the key"),
      value: Yup.string().required("Please enter the value"),
    }),

    onSubmit: values => {
      save(values)
    },
  })

  return (
    <Modal
      isOpen={showEditModal}
      toggle={() => {
        tog_center(null)
      }}
      centered
      size="md"
    >
      <React.Fragment>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Configuration</h5>
        </div>

        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <Label className="form-label">Key</Label>
              <Input
                name="name"
                className="form-control"
                placeholder="Enter key of the configuration"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || ""}
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <Label className="form-label">Value</Label>
              <div className="input-group auth-pass-inputgroup">
                <Input
                  name="value"
                  value={validation.values.value || ""}
                  type="text"
                  placeholder="Enter Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.value && validation.errors.value
                      ? true
                      : false
                  }
                />
              </div>
              {validation.touched.value && validation.errors.value ? (
                <FormFeedback type="invalid">
                  {validation.errors.value}
                </FormFeedback>
              ) : null}
            </div>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={cancel}
            >
              Cancel
            </button>
          </div>
        </Form>
      </React.Fragment>
    </Modal>
  )
}
