import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Button,
  Form,
  Label,
  Input,
  FormFeedback,
  Modal,
  UncontrolledAlert,
} from "reactstrap"

import { Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"


// import images
import logo1 from "../../assets/images/Logo-final.png"
import { resetProfileFlag } from "store/actions"
import { Auth } from "aws-amplify"

const ForgetPasswordPage = () => {
  const [email, setEmail] = useState("")
  const [stage, setStage] = useState(1)
  const [code, setCode] = useState()
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [modal_center, setmodal_center] = useState(false)
  const [error, setError] = useState(null)

  const toggleForgotPasswordToast = () => {
    setToastForgotPassword(!toastForgotPassword)
  }

  const navigate = useNavigate()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      setEmail(values.email)
      sendCode(values)
    },
  })

  const verificationCodeValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      code: "",
      password: "",
      confirmPassword: "",
    },

    validationSchema: Yup.object({
      code: Yup.string().required("Please enter verification code"),
      password: Yup.string().required("Please enter password"),
      confirmPassword: Yup.string()
        .required("Please re-enter password")
        .oneOf([Yup.ref("password")], "Your passwords do not match."),
    }),

    onSubmit: values => {
      setCode(values.code)
      setPassword(values.password)
      setConfirmPassword(values.confirmPassword)
      values = { ...values, email: email }
      changePassword(values)
    },
  })

  const changePassword = async values => {

     try {
      await Auth.forgotPasswordSubmit(values.email, code, password);
      console.log('Password reset successful!');
      tog_center()
    } catch (error) {
      console.error('Error resetting password:', error.message);
    }
  }

  const sendCode = async data => {

    try {
      await Auth.forgotPassword(data.email);
      console.log('Password reset code sent!');
      setError(null)
      setStage(2)
    } catch (error) {
      console.error('Error sending reset code:', error.message);
      setError(error)
    }


  }

  // const getUser = email => {
  //   return new CognitoUser({
  //     Username: email,
  //     Pool,
  //   })
  // }

  function tog_center(status) {
    setmodal_center(!modal_center)
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <img src={logo1} height="34" />
                  </div>
                  <div className="p-2">
                    {stage === 1 && (
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          //return false
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                                validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                          {error != null && (
                            <div className="mb-3 mt-3">
                              <UncontrolledAlert color="danger" role="alert">
                                {error.__type === 'LimitExceededException' ? error.message : 'Invalid credential'}
                              </UncontrolledAlert>
                            </div>
                          )}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Send Verification Code
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                    {stage == 2 && (
                      <form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          verificationCodeValidation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">
                            Verification Code
                          </Label>
                          <Input
                            name="code"
                            className="form-control"
                            placeholder="Enter code"
                            type="text"
                            onChange={verificationCodeValidation.handleChange}
                            onBlur={verificationCodeValidation.handleBlur}
                            value={verificationCodeValidation.values.code || ""}
                            invalid={
                              verificationCodeValidation.touched.code &&
                                verificationCodeValidation.errors.code
                                ? true
                                : false
                            }
                          />
                          {verificationCodeValidation.touched.code &&
                            verificationCodeValidation.errors.code ? (
                            <FormFeedback type="invalid">
                              {verificationCodeValidation.errors.code}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Enter password"
                            type="password"
                            onChange={verificationCodeValidation.handleChange}
                            onBlur={verificationCodeValidation.handleBlur}
                            value={
                              verificationCodeValidation.values.password || ""
                            }
                            invalid={
                              verificationCodeValidation.touched.password &&
                                verificationCodeValidation.errors.password
                                ? true
                                : false
                            }
                          />
                          {verificationCodeValidation.touched.password &&
                            verificationCodeValidation.errors.password ? (
                            <FormFeedback type="invalid">
                              {verificationCodeValidation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            Re-enter password
                          </Label>
                          <Input
                            name="confirmPassword"
                            className="form-control"
                            placeholder="Retype the password"
                            type="password"
                            onChange={verificationCodeValidation.handleChange}
                            onBlur={verificationCodeValidation.handleBlur}
                            value={
                              verificationCodeValidation.values
                                .confirmPassword || ""
                            }
                            invalid={
                              verificationCodeValidation.touched
                                .confirmPassword &&
                                verificationCodeValidation.errors.confirmPassword
                                ? true
                                : false
                            }
                          />
                          {verificationCodeValidation.touched.confirmPassword &&
                            verificationCodeValidation.errors.confirmPassword ? (
                            <FormFeedback type="invalid">
                              {
                                verificationCodeValidation.errors
                                  .confirmPassword
                              }
                            </FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Reset Password
                            </button>
                          </Col>
                        </Row>
                      </form>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
              </div>
            </Col>
            <Modal
              isOpen={modal_center}
              toggle={() => {
                tog_center(null)
              }}
              centered
              size="sm"
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0 text-success">Success</h5>
                <button
                  type="button"
                  onClick={() => {
                    tog_center(false)
                    navigate("/")
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span
                    aria-hidden="true"
                    onClick={() => setmodal_center(false)}
                  >
                    &times;
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <div style={{ color: "success" }}>
                  {" "}
                  Password has been reset successfully. Please login with new
                  password
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    navigate("/")
                  }}
                >
                  Ok
                </button>
              </div>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ForgetPasswordPage
