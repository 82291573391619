import QdContainer from "components/Common/QdContainer"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Badge, Button, Card, CardBody, Col, Row, Table } from "reactstrap"
import PublishedData from "./PublishedData"
import StaggedData from "./StaggedData"
import Cookies from "js-cookie"

const EmailTemplate = () => {
  const [loading, setLoading] = useState(false)
  //const { locationId, locationName } = useParams()
  const [data, setData] = useState()
  const [isNew, setIsNew] = useState(true)
  const [publishedData, setPublishedData] = useState()
  const [stagedData, setStagedData] = useState()
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [currentData, setCurrentData] = useState()
  const [isEditing, setIsEditing] = useState(false)
  const [showStaggedData, setShowStaggedData] = useState(false)

  const location = useLocation()
  const { state } = location || {}

  const [mLocId, setMLocId] = useState()
  const [locationId, setLocationId] = useState()
  const [locationName, setLocationName] = useState()
  const [hasPublished, setHasPublished] = useState(false)

  const api_url = process.env.REACT_APP_API_URL

  const navigate = useNavigate()

  useEffect(() => {
    console.log(state)
    setMLocId(state?.m_loc_id)

    setLocationId(state?.locationId)
    setLocationName(state?.locName)
    getEmailTemplate()
    setIsEditing(false)
  }, [data])

  const getEmailTemplate = async () => {
    try {
      setLoading(true)
      const mLocId = state.m_loc_id
      const token = Cookies.get("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(
        api_url + "email/templatemapping/" + mLocId,
        { headers }
      )
      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      if (jsonData) {
        if (jsonData.published && jsonData.has_tenant_cfg) {
          setPublishedData(jsonData.published)
          setSelectedTemplate("published")
          setHasPublished(true)
          setShowStaggedData(false)
        }

        if (jsonData.staged) {
          setStagedData(jsonData.staged)
        }

        setIsNew(false)
      }
      setCurrentData(publishedData)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const isEmpty = obj => {
    return Object.keys(obj).length === 0
  }

  const showStagged = () => {
    if (selectedTemplate !== "published") setSelectedTemplate("published")
    else setSelectedTemplate("staged")
  }

  const goBack = () => {
    navigate("/location-management")
  }

  const checkHasPublished = () => {
    console.log(selectedTemplate == "published")
    return selectedTemplate == "published"
  }

  return (
    <QdContainer>
      {loading && <div>Loading..</div>}
      {selectedTemplate == "published" &&
        !showStaggedData &&
        !loading &&
        publishedData && (
          <PublishedData
            locationId={locationId}
            showStagged={showStagged}
            m_loc_id={mLocId}
            getEmailTemplate={getEmailTemplate}
            locationName={locationName}
          />
        )}
      {selectedTemplate !== "published" && !loading && stagedData && (
        <StaggedData
          locationId={locationId}
          m_loc_id={mLocId}
          showPublished={showStagged}
          data={stagedData}
          hasPublished={hasPublished}
          locationName={locationName}
        />
      )}
      {!stagedData && !publishedData && !loading && (
        <Card>
          <CardBody className="text-center">
            <p>No configuration found.</p>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={goBack}
            >
              Back
            </Button>
          </CardBody>
        </Card>
      )}
    </QdContainer>
  )
}

export default EmailTemplate
