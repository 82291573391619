import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import Cookies from "js-cookie"

const Navbar = props => {
  const [dashboard, setDashboard] = useState(false)
  const [locationManagement, setLocationManagement] = useState(false)
  const [app, setapp] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [window.location.pathname])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Scheduling")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/location-management">
                    <i className="fas fa-location-arrow me-2"></i>
                    {props.t("Location Management")}
                  </Link>
                </li>

                {Cookies.get("role") == "admin" && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setapp(!app)
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-customize me-2"></i>
                      {props.t("Admin")} <div className="arrow-down"></div>
                    </Link>
                    <div className={classname("dropdown-menu", { show: app })}>
                      <Link to="/user-admin" className="dropdown-item" onClick={e=> {
                        setapp(false)
                        navigate('/user-admin')
                        
                      }}>
                        {props.t("User")}
                      </Link>
                      <Link to="/notification" className="dropdown-item" onClick={e=> {
                        setapp(false)
                        navigate('/notification')
                        
                      }}>
                        {props.t("Notification")}
                      </Link>
                      <Link to="/req-scheduler" className="dropdown-item" onClick={e=> {
                        setapp(false)
                        navigate('/req-scheduler')
                        
                      }}>
                        {props.t("Pending Request Schedule")}
                      </Link>
                      <Link to="/loc-config-open-dock" className="dropdown-item" onClick={e=> {
                        setapp(false)
                        navigate('/loc-config-open-dock')
                        
                      }}>
                        {props.t("OpenDock Location Configuration")}
                      </Link>
                     
                      <Link to="/tenant-customisation" className="dropdown-item" onClick={e=> {
                        setapp(false)
                        navigate('/tenant-customisation')
                        
                      }}>
                        {props.t("Customisation")}
                      </Link>
                      <Link to="/auth-config" className="dropdown-item" onClick={e=> {
                        e.preventDefault()
                        setapp(false)
                        navigate('/auth-config')
                        
                      }}>
                        {props.t("Authorisation Configuration")}
                      </Link>
                      <Link to="/new-location" className="dropdown-item" onClick={e=> {
                        e.preventDefault()
                        setapp(false)
                        navigate('/new-location')
                        
                      }}>
                        {props.t("New Location")}
                      </Link>
                      <Link to="/master-loc" className="dropdown-item" onClick={e=> {
                        e.preventDefault()
                        setapp(false)
                        navigate('/master-loc')
                        
                      }}>
                        {props.t("New Master Location")}
                      </Link>
                      <Link to="/email-templates" className="dropdown-item" onClick={e=> {
                        e.preventDefault()
                        setapp(false)
                        navigate('/email-templates')
                        
                      }}>
                        {props.t("Email Template")}
                      </Link>
                      <Link to="/system-configuration" className="dropdown-item" onClick={e=> {
                        e.preventDefault()
                        setapp(false)
                        navigate('/system-configuration')
                        
                      }}>
                        {props.t("System Configuration")}
                      </Link>
                      <Link to="/appt-sys" className="dropdown-item" onClick={e=> {
                        e.preventDefault()
                        setapp(false)
                        navigate('/appt-sys')
                        
                      }}>
                        {props.t("Appointment Systems")}
                      </Link>
                      <Link to="/email-appt-report" className="dropdown-item" onClick={e=> {
                        e.preventDefault()
                        setapp(false)
                        navigate('/email-appt-report')
                        
                      }}>
                        {props.t("Email Appointment Report")}
                      </Link>
                    </div>
                  </li>
                )}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
